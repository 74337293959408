// -----------------------------------------------------------------------------
// Footer style
// -----------------------------------------------------------------------------
#footer {
    border-top: 1px solid $color01;
    padding: 50px 0;
    font-size: 13px;
    a {
        color: $text-color;
    }

    .fNav01 {
        position: relative;
        margin-bottom: 25px;
        padding-bottom: 40px;

        &:after {
            position: absolute;
            width: 100%;
            max-width: 1400px;
            height: 1px;
            content: '';
            background-color: $color01;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .ttl {
            font-size: 14px;
            font-weight: bold;
            line-height: 2;
            margin-bottom: 7px;
        }

        .subNav {
            a {
                line-height: 2.385;
            }
        }

        .img {
            line-height: 0;
            margin-top: 25px;
        }
        .col06 .img a {
            display: inline-block;
        }
    }

    .fNav02 {
        .fLogo {
            line-height: 0;
            width: 255px;
        }

        .copyright {
            font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
            color: $color06;
            line-height: 2.15;
            width: calc(100% - 300px);
        }
    }

    .btnContact {
        display: block;
        font-size: 14px;
        line-height: 2;
        padding: 12px 10px 11px;
        font-weight: bold;
        border: 1px solid $color01;
        border-radius: 5px;
        text-align: center;
        transition: opacity .3s;

        &:hover {
            text-decoration: none;
            opacity: .7;
        }
    }
    .footer_title {
        font-size: 16px;
        font-weight: bold;
        line-height: 2;
        margin-bottom: 7px;
        & + div > * {
            padding-bottom: 20px;
        }
    }
    .menu {
        a {
            line-height: 2.385;
        }
    }
    @include media-up(769) {
        .fNav01 {
            .inner {
                display: flex;
                flex-wrap: wrap;
            }

            .col {
                width: calc(100%/6);
                padding-right: 15px;

                &:last-child {
                    padding-right: 0;
                }
            }

            .col01 {
                width: calc(163/1000*100%);
                order: 1;
            }

            .col02 {
                width: calc(148/1000*100%);
                // width: calc(128/1000*100%);
                order: 2;
            }

            .col03 {
                width: calc(125/1000*100%);
                // width: calc(115/1000*100%);
                order: 3;
            }

            .col04 {
                width: calc(160/1000*100%);
                // width: calc(190/1000*100%);
                order: 4;
            }

            .col05 {
                width: calc(210/1000*100%);
                order: 5;
                .footer_title {
                    display: none;
                }
                .ttl {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                li {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 2;
                    margin-bottom: 5px;
                }
            }

            .col06 {
                width: calc(194/1000*100%);
                padding-top: 6px;
                order: 6;
                .img a {

                    transition: $transition-duration;
                    &:hover {
                        opacity: .7;
                    }
                }
            }
            .col07 {
                margin-top: 10px;
                order: 7;
            }
        }

        .fNav02 {
            .inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    @include media-down(sp) {
        padding: 30px 0;

        a:hover {
            text-decoration: none;
        }
        .fNav01 {
            padding: 0 10px 25px;
            .subNav {
                display: none;
                padding-bottom: 10px;
            }

            .img {
                text-align: center;
                margin-top: 17px;
            }

            .col {
                border-bottom: 1px solid $color09;
                &07 {
                    border-bottom: 1px solid $color09;
                    &>.footer_title,
                    &>.ttl {
                        position: relative;
                        margin-bottom: 0;
                        padding: 14px 35px 13px 0;
                        font-size: 16px;
                        span {
                            display: block;
                            padding: 14px 35px 13px 0;
                            font-size: 16px;
                        }

                        &:after {
                            position: absolute;
                            width: 21px;
                            height: 1px;
                            content: '';
                            background-color: $color09;
                            right: 5px;
                            top: 50%;
                        }

                        &:before {
                            position: absolute;
                            height: 21px;
                            width: 1px;
                            content: '';
                            background-color: $color09;
                            top: calc(50% - 10px);
                            right: 15px;
                            transition: all .3s;
                        }

                        &.active {
                            &:before {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
                &>.footer_title,
                &>.ttl {
                    position: relative;
                    margin-bottom: 0;
                    padding: 14px 35px 13px 0;
                    font-size: 16px;
                    a {
                        display: block;
                        padding: 14px 35px 13px 0;
                        font-size: 16px;
                    }

                    &:after {
                        position: absolute;
                        width: 21px;
                        height: 1px;
                        content: '';
                        background-color: $color09;
                        right: 5px;
                        top: 50%;
                    }

                    &:before {
                        position: absolute;
                        height: 21px;
                        width: 1px;
                        content: '';
                        background-color: $color09;
                        top: calc(50% - 10px);
                        right: 15px;
                        transition: all .3s;
                    }

                    &.active {
                        &:before {
                            transform: rotate(90deg);
                        }
                    }
                    &.noChild {
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
                &05 {
                    border-bottom: none;
                    .footer_title {
                        padding: 0;
                        &:before,
                        &:after {
                            display: none;
                        }
                    }
                }
                &06 {
                    border-bottom: none;
                }
            }
            .menu {
                display: none;
            }
            #menu-footer-column5,
            .tagLst {
                display: flex;
                flex-wrap: wrap;
                border-top: 1px solid $color09;
                padding: 20px 0 14px;
                li,
                .ttl {
                    position: relative;
                    font-weight: 500;
                    margin-right: 23px;
                    padding-right: 25px;
                    margin-bottom: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:after {
                        position: absolute;
                        height: 15px;
                        width: 1px;
                        content: '';
                        background-color: $color-black;
                        right: 0;
                        top: calc(50% - 7px);
                    }
                    &:first-child {
                        display: none;
                    }
                }
            }
        }

        .fNav02 {
            text-align: center;

            .fLogo {
                width: 100%;
                margin-bottom: 17px;

                img {
                    max-width: 255px;
                }
            }

            .copyright {
                width: 100%;
                font-size: 11px;
                line-height: 1.55;
            }
        }

        .btnContact {
            max-width: 251px;
            margin: 0 auto;
            padding: 12px 45px 11px;
            color: $color-white;
            border-radius: 5px;
            background: $color04 image("common/icon_arrow_right_white02.svg") no-repeat right 28px top 50%;

        }
    }
}
