// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Home page.
// -----------------------------------------------------------------------------
#pageHome #contents {
    padding: 0 0 80px;
}
.sectionNews {
    width: 100%;
    margin-bottom: 100px;
    &02 {
        .tab02 {
            margin-bottom: 25px;
        }
        .headTab {
            justify-content: center;
        }
        .headline1 {
            display: none;
        }

    }
    @include media-down(sp) {
        margin-bottom: 100px;
    }
}

.sectionInfos {
    margin-bottom: 110px;

    .innerMax {
        display: flex;
        justify-content: space-between;

        .blockLeft {
            background: url(#{$base-url}img/home/img_01.jpg) no-repeat 50% 50%;
            background-size: 100% auto;
        }

        .blockRight {
            background: url(#{$base-url}img/home/img_02.jpg) no-repeat 50% 50%;
            background-size: 100% auto;
        }

        .blockInfos {
            transition: all .3s;
            cursor: pointer;
            @include media-up(sp) {
                &.wp-block-column {
                    flex-basis: calc(50% - 10px) !important;
                }
                &.blockRight {
                    margin-left: 20px !important;
                }
                &:hover {
                    background-size: calc(100% + 40px) auto;

                    .box .ttl:after {
                        bottom: 5px;
                    }
                }
            }
        }
    }

    // @include media-up(1025) {
    // 	.blockLeft:hover {
    // 		opacity: 0.7;
    // 	}
    // }


    @include media-down(sp) {
        margin-bottom: 60px;
        margin-top: 60px;

        .innerMax {
            // flex-direction: column;

            .blockLeft {
                margin-bottom: 15px;
                position: relative;

                &::after {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 30px;
                    right: 20px;
                    background: url(#{$base-url}img/common/icon_arrow_right_white.svg) no-repeat 50% 50%;
                    background-size: 20px 19px;
                    content: '';
                }
            }
        }
    }
}

.blockInfos {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: calc(50% - 10px);
    height: 0;
    padding-bottom: 31.15%;
    transition: opacity 0.3s;
    overflow: hidden;

    .box {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 440px;
        width: 100%;

        &.style {
            top: calc(50% + 75px);
        }

        .ttl {
            position: relative;
            padding-bottom: 30px;
            font-size: 32px;
            color: $color-white;
            font-weight: bold;
            line-height: 1.3;
            text-align: center;
            transition: all .3s;

            &::after {
                position: absolute;
                width: 80px;
                height: 3px;
                bottom: 0;
                left: 50%;
                background-color: $color-white;
                transform: translateX(-50%);
                content: '';
            }
        }

        .listInfos {
            margin-top: 30px;
        }
    }

    @include media-down(1300) {
        .box {
            &.style {
                top: 58%;
            }
        }
    }

    @include media-down(1024) {
        .box {
            .ttl {
                font-size: 25px;
                padding-bottom: 13px;

            }
        }
    }

    @include media-down(sp) {
        padding-bottom: 80.6%;
        width: 100%;
    }
}

.listInfos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        display: flex;
        width: calc(50% - 5px);
        margin-bottom: 10px;
        transition: all 0.3s;
        opacity: 1;

        a {
            font-size: 16px;
            line-height: 1.3;
            color: $color-white;
            display: block;
            width: 100%;
            position: relative;
            padding: 15px 35px;
            font-weight: bold;
            border-radius: 5px;
            background: url(#{$base-url}img/common/icon_arrow_right_white.svg) no-repeat rgba(255, 255, 255, 0.3);
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
            text-align: center;
            text-decoration: none;

        }
    }

    @include media-up(sp) {
        li a {
            transition: $transition-duration;
            &:hover {
                background-color: $color04;
            }
        }
    }

    @include media-down(1024) {
        max-width: 320px;
        margin: 0 auto;

        li {
            a {
                font-size: 12px;

            }
        }
    }

    @include media-down(sp) {
        li {
            a {
                padding: 15px 25px;
                color: $color-white;
                background-position-x: calc(100% - 10px);
                background-color: $color04;
                font-size: 14px;
            }
        }
    }

    @include media-down(374) {
        max-width: 260px;

        li {
            a {
                padding: 10px 20px;
                color: #FFF;
                background-position-x: calc(100% - 8px);
                background-color: #00263E;
                background-size: 8px 8px;
                font-size: 10px;
            }
        }
    }
}

.blockInfos {
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 1;
	width: calc(50% - 10px);
	height: 0;
	padding-bottom: 31.15%;
	transition: opacity 0.3s;
	overflow: hidden;

	.box {
		position: absolute;
		z-index: 3;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 440px;
		width: 100%;

		&.style {
			top: calc(50% + 75px);
		}

		.ttl {
			position: relative;
			padding-bottom: 30px;
			font-size: 32px;
			color: $color-white;
			font-weight: bold;
			line-height: 1.3;
			text-align: center;
            a {
                color: $color-white;
                text-decoration: none;
            }
			&::after {
				position: absolute;
				width: 80px;
				height: 3px;
				bottom: 0;
				left: 50%;
				background-color: $color-white;
				transform: translateX(-50%);
                content: '';
                transition: $transition-duration;
			}
		}

		.listInfos {
			margin-top: 30px;
		}
	}

	@include media-down(1300) {
		.box {
			&.style {
				top: 58%;
			}
		}
	}

	@include media-down(1024) {
		.box {
			.ttl {
				font-size: 25px;
				padding-bottom: 13px;

			}
		}
	}

	@include media-down(sp) {
		// padding-bottom: 80.6%;
        // width: 100%;
        padding-bottom: 0;
        width: 100%;
        height: 72vw;
        background-size: cover !important;
        &.wp-block-column {
            flex-basis: 100% !important;
            &:nth-child(2n) {
                margin-left: 0;
            }
        }
	}
}



.sectionProject {
    background-color: $color04;
    margin-bottom: 110px;
    padding: 130px 0 85px;

    .ttl {
        margin-bottom: 55px;
        color: $color-white;
        font-size: 40px;
        font-weight: bold;
        line-height: 1.3;
    }

    .boxText {
        display: flex;
        margin-bottom: 40px;

        .boxLeft {
            width: 50%;
            padding: 15px 40px 50px 0;
            border-right: 1px solid $color-white;
            box-sizing: border-box;
            .wp-block-buttons {
                width: 100%;
                text-align: right;
            }
        }

        .boxRight {
            width: 50%;
            padding: 15px 0 50px 40px;
            .wp-block-buttons {
                text-align: left;
            }
        }

        .txt {
            margin-bottom: 30px;
            color: $color-white;
            font-size: 17px;
            font-weight: bold;
            line-height: 2;
        }
    }

    @include media-up(sp) {
        .wp-block-column {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
        }
    }
    @include media-down(sp) {
        padding: 67px 0 50px 0;
        margin: 0 -20px 30px -20px;

        .ttl {
            padding: 0 10px;
            font-size: 20px;
            line-height: 1.55;
            margin-bottom: 25px;
        }

        .boxText {
            display: block;
            margin-bottom: 0;

            .txt {
                font-size: 14px;
            }

            .boxLeft {
                width: 100%;
                padding: 0 10px;
                border-right: 0;
                .wp-block-buttons {
                    text-align: center;
                }
            }

            .boxRight {
                display: none;
                .wp-block-buttons {
                    text-align: center;
                }
            }
        }
    }
}

.companySection {
    padding: 90px 0 100px;
    background-color: $color07;

    .headline1 {
        // margin-bottom: 65px;
        margin-bottom: 25px;
        padding-left: 45px;
    }
    .wp-block-columns {
        margin-bottom: 0;
        &:last-child {
        }
    }
    &02 {
        background-color: $color-white;
        padding: 40px 0 0;
        .companyBlock {
            padding: 40px 80px 40px 0;
            border-bottom: 1px solid $color01;
            background: image('common/icon_arrow_right02.svg') no-repeat right 15px top 50%;
            background-size: 18px auto;
            transition: $transition-duration;
            &:hover {
                background-position: right 0 top 50%;
            }
            &:last-child {
                border-bottom: none;
            }
            .image {
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border: 1px solid $color06;
                border-radius: 10px;
                overflow: hidden;
                min-height: 185px;
                a {
                    color: $text-color;
                    font-size: 14px;
                    font-weight: bold;
                    text-decoration: none;
                }
                figcaption {
                    margin-bottom: 0;
                }
                .wp-block-image {
                    margin-bottom: 0;
                }
            }
            .listSocial {
                width: 100%;
                & > * {
                    border-top: none;
                }
            }
            .boxText {
                margin-top: 10px;
                margin-bottom: 0;
                display: flex;
                flex-wrap: wrap;
                // align-content: space-between;
                align-items: center;
            }

        }
        &.companySection03 {
            background-color: transparent;
            .wp-block-image img {
                margin-bottom: 5px;
            }
            .companyBlock {
                border-bottom: none;
                background-color: $color-white;
                margin-bottom: 25px;
                background-position: right 35px top 50%;
                padding: 35px 80px 35px 35px;
                justify-content: space-between;
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    background-position: right 20px top 50%;
                }
                .image {
                    border: none;
                    margin-top: 12px;
                    figcaption {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
                .listSocial {
                    p {
                        width: auto;
                        min-width: 42%;

                        @include media-down(1200) {
                            width: 100%;
                        }
                    }
                    & > * {
                        padding-top: 28px;
                        justify-content: initial;
                    }
                }
            }
            padding: 40px 25px 0;
        }
    }
    @include media-down(sp) {
        margin: 0 -20px;
        padding: 80px 0 90px;

        .headline1 {
            margin-bottom: 40px;
            padding-left: 0;
        }
        .wp-block-column {
            flex-basis: 100% !important;
            margin-left: 0 !important;
        }
        &02 {
            padding: 20px 0 0;
            .companyBlock {
                padding-right: 0;
                background: none;
                .image {
                    margin-bottom: 15px;
                }
                .boxText {
                    padding-right: 60px;
                    background: image('common/icon_arrow_right02.svg') no-repeat right 15px top 50%;
                }
            }
            &.companySection03 {
                padding: 20px 0 0;
                .companyBlock {
                    padding: 35px 20px;
                    .boxText {
                        padding-right: 40px;
                        background-position: right 0px top 50%;
                    }
                    .image {
                        margin-top: 0;
                        padding: 15px;
                        min-height: 0;
                    }
                }
            }
        }
    }
}

.companyGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.companyBlock {
    position: relative;
    width: calc(50% - 15px);
    margin-bottom: 30px;
    padding: 65px 35px 40px;
    transition: all .3s;
    cursor: pointer;

    // background-color: $color-white;
    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-white;
        transition: all .3s;
    }

    &:hover {
        &:after {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
        }
        @include media-up(sp) {
            .titleLink {
                background-position: right 0 top 5px;
            }
        }
    }

    .blockInner {
        position: relative;
        z-index: 1;
    }

    .titleLink {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        position: relative;
        margin-bottom: 48px;
        padding-right: 35px;
        background: url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat;
        // background-position-x: 100%;
        // background-position-y: 5px;
        background-position: right 10px top 5px;
        background-size: 18px 18px;
        text-decoration: none;
        margin-right: -10px;
        transition: $transition-duration;

        .imageTitle {
            margin-bottom: 15px;
        }

        .subTitle {
            font-size: 14px;
            line-height: 1.3;
            color: $text-color;
        }
    }

    .boxText {
        margin-bottom: 40px;
        color: $text-color;

        .ttl {
            margin-bottom: 12px;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.4;
        }

        .txt {
            font-size: 16px;
            line-height: 1.6;
        }
    }

    .listSocial {
        width: 100%;
        & > * {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 15px;
            border-top: 1px solid $color02;
        }
        &.fullwidth {
            width: 100%;
            & > * {
                display: block;
                p {
                    width: 100%;
                }
            }
        }
        p {
            width: calc(50% - 5px);

            &.facebook {
                padding: 7px 5px 4px 25px;
                background: url(#{$base-url}img/common/icon_facebook.svg) no-repeat;
                background-position: 0 6px;
            }

            &.instagram {
                padding: 7px 5px 4px 25px;
                background: url(#{$base-url}img/common/icon_instagram.svg) no-repeat;
                background-position: 0 6px;
            }

            &.youtube {
                padding: 7px 5px 4px 29px;
                background: url(#{$base-url}img/common/icon_youtube.svg) no-repeat;
                background-position: 0 6px;
            }
            &.auctionNote {
                padding: 7px 5px 4px 25px;
                background: url(#{$base-url}img/common/note_logo.svg) no-repeat;
                background-position: 0 6px;
            }

            a {
                font-size: 13px;
                font-weight: bold;
                line-height: 1.3;
                text-decoration: none;
                color: $color08;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &.fullwidth {
        width: 100%;
        &:after {
            display: none;
        }
    }

    // @include media-up(1025) {
    //     .titleLink {
    //         &:hover {
    //             opacity: 0.7;
    //         }
    //     }
    // }

    @include media-up(sp) {
        .blockInner {
            height: 100%;
            & > * {
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
            }
        }
    }
    @include media-down(1200) {
        padding: 40px 25px 30px 25px;
        width: calc(50% - 10px);

        .titleLink {
            margin-bottom: 38px;
            padding-right: 35px;
            background-position-x: calc(100% - 6px);
            background-position-y: 50%;
            background-size: 23px 20px;
            margin-right: 0;

            .imageTitle {
                margin-bottom: 10px;
            }

            .subTitle {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .boxText {
            margin-bottom: 30px;

            .ttl {
                font-size: 14px;
            }

            .txt {
                font-size: 14px;
                line-height: 1.58;
            }
        }

        .listSocial {
            padding-top: 0;
            border-top: 0;

            p {
                width: 100%;
            }
        }
    }

    @include media-down(650) {
        width: 100%;
        margin-bottom: 20px;
    }
}

