// -----------------------------------------------------------------------------
// Form style
// -----------------------------------------------------------------------------
input,
select,
textarea {
    font-size: 16px;
    line-height: 1.3125;
    padding: 10px;
    border: 1px solid $color16;
    border-radius: 5px;
    width: 100%;
    outline: none;
    background-color: $color-white;
    &.halfWidth {
        max-width: 271px;
    }
    &::placeholder {
        color: $color18;
    }
    @include media-down(sp) {
        &.halfWidth {
            max-width: none;
        }
    }
}
input {
    max-width: 600px;
}
.checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    line-height: 1.33;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    margin-right: 60px;
    &:last-child {
        margin-right: 0;
    }
    &.agree {
        font-weight: bold;
        // margin-bottom: 15px;
        &.error {
            .label {
                border-color: $color17;
            }
        }
    }
    &~.checkbox {
        margin-right: 20px;
        @include media-down(sp) {
            margin-top: 10px;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.label {
            &:after {
                display: block;
            }
        }
    }

    .label {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $color-white;
        border: 1px solid $color16;
        border-radius: 5px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid $color-black;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

    }
}
select {
    padding-right: 35px;
    appearance: none;
    background: $color-white image('common/icon_select.svg') no-repeat right 18px top 50%;
    background-size: 9px auto;

    &::-ms-expand {
        display: none;
    }
}
.contactForm {
    .noteList {
        font-size: 14px;
        line-height: 1.357;
        margin-top: 10px;
    }
}
