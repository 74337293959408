// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Contact page
// -----------------------------------------------------------------------------
.contactBox {
    margin-bottom: 96px;
    &:last-child {
        margin-bottom: 0;
    }
    .tel {
        font-size: 40px;
        line-height: 1.325;
        color: $text-color;
        text-decoration: none;
        pointer-events: none;
    }
    .btnLink {
        margin-top: 12px;
    }
    .txt {
        font-size: 14px;
        line-height: 1.357;
    }
    @include media-down(sp) {
        .txt {
            font-size: 12px;
        }
        .tel {
            font-size: 30px;
        }
    }
}
.labelBox {
    display: flex;
    font-size: 15px;
    color: $color-black;
    line-height: 1.33;
    font-weight: bold;
    margin-bottom: 8px;
    label {
        margin-right: 0;
    }
    span {
        display: block;
        color: $color17;
        margin-left: 5px;
    }
}
.formGroup {
    margin-bottom: 35px;
    .inputBox {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &.errorWrap {
        input,
        select,
        textarea {
            border-color: $color17;
        }
        .checkbox {
            .label {
                border-color: $color17;
            }
        }
    }
}
.contactForm {
    background-color: $color07;
    padding: 35px 40px;
    .error {
        display: block;
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
        color: $color17;
        font-weight: bold;
    }
    .agreeWrap {
        margin-bottom: 15px;
    }
    .link {
        display: block;
        font-size: 14px;
        line-height: 1.357;
        color: $color19;
        text-decoration: underline;
        margin-top: 20px;
        border:none;
        outline: none;
        background: transparent;
        cursor: pointer;
    }
    .txt02 {
        font-size: 16px;
        line-height: 1.3125;
        padding: 0 10px;
    }
    .mwform-checkbox-field {
        display: inline-block;
        position: relative;
        // padding-left: 30px;
        font-size: 15px;
        line-height: 1.33;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        margin-right: 60px;
        input[type="checkbox"]{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked~.mwform-checkbox-field-text {
                &:after {
                    display: block;
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
        &.agree {
            font-weight: bold;
            // margin-bottom: 15px;
            &.errorWrap {
                .label {
                    border-color: $color17;
                }
            }
        }
        &~.checkbox {
            margin-right: 20px;
            @include media-down(sp) {
                margin-top: 10px;
            }
        }
        .mwform-checkbox-field-text {
            position: relative;
            padding-left: 30px;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                content: '';
                background-color: $color-white;
                border: 1px solid $color16;
                border-radius: 5px;
            }
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid $color-black;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

        }
        label {
            cursor: pointer;
        }
    }
    .mw_wp_form_confirm {
        .txt,
        .agreeWrap,
        .noteList,
        .phone {
            display: none;
        }
        .inputBox {
            font-size: 16px;
            line-height: 1.3125;
            padding: 0 10px;
            input[name="__children[byPhone][]"] + br {
                display: none;
            }
        }
    }
}
.agreeWrap .mwform-checkbox-field-text {
    font-weight: bold;
}
.mw_wp_form_confirm .labelBox span {
    display: none;
}
@include media-down(sp) {
    #phone {
        margin-top: 10px;
    }
    .formGroup {
        margin-bottom: 20px;
    }
    .contactForm {
        padding: 20px;
    }
    .contactBox {
        margin-bottom: 50px;
    }
    .txt02 {
        font-size: 14px;
    }
}
