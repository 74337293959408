// -----------------------------------------------------------------------------
// Tab style
// -----------------------------------------------------------------------------



.tabArea {
    margin-bottom: 30px;
    .headline1 {
        min-width: 140px;
        margin-bottom: 0;
    }
    .headTab {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 50px;
        padding-right: 16px;
        &.yearBlock {
            margin-top: 30px;
            background-color: $color07;
            .headline1 {
                display: none;
            }
            .yearList {
                max-width: 865px;
                padding: 15px 20px;
                margin: 0 auto;
                justify-content: flex-start;
                li {
                    padding: 3px 20px;
                    a {
                        padding: 0;
                        border-bottom: none;
                        font-weight: 500;
                        &:after {
                            display: none;
                        }
                    }
                    &.active {
                        a {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    @include media-down(1024) {
        .headTab {
            padding-right: 0;
        }
    }
    @include media-down(sp) {
        margin-bottom: 20px;
        .headline1 {
            margin-bottom: 20px;
        }
        .headTab {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;
            &.yearBlock {
                margin-bottom: 30px;
            }
        }
    }
}


.tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
	li {
		position: relative;
		display: inline-block;
        padding: 0 20px;
		&:after {
			position: absolute;
			right: 0;
			top: 50%;
			width: 1px;
			height: 13px;
			background-color: $color-black;
			transform: translateY(-50%);
			content: '';
		}
		&:last-child {
			border-right: 0;
			&:after {
				content: none;
			}
		}
		a {
			display: block;
			width: 100%;
			font-size: 14px;
			font-weight: bold;
			color: $color-black;
			line-height: 1.3;
			text-decoration: none;
		}
    }

	@include media-up(sp) {
        margin-bottom: 15px;
		li {
			a {
                padding: 15px 0;
                border-bottom: 2px solid transparent;
				transition: $transition-duration;
				&:hover {
					opacity: 0.7;
				}
            }
            &.active {
                a {
                    border-bottom-color: $color04;
                }
            }
        }
	}

	@include media-down(sp) {
        width: calc(100% - 20px);
        margin: 0 10px;
        padding: 6px 12px 16px 12px;
        background-color: $color03;
        flex-wrap: wrap;
        justify-content: flex-start;
        li {
            padding: 0 15px;
            text-align: center;
            margin-top: 10px;
        }
        &.yearList {
            width: 100%;
            padding: 6px 12px 16px 12px !important;
            li {
                padding: 0 15px !important;
            }
        }
    }
    @include media-down(374) {
        li {
            padding: 0 10px;
            a {
                font-size: 12px;
            }

        }
        &.yearList {
            li {
                padding: 0 10px !important;
            }
        }
    }
}

.tabContents {
    margin: 0 10px;
	.tabBox {
		display:none;
    }
    #tab01 {
        display:block;
    }
    .pagerLink {
        display: none;
    }
    .listNews.listReverse li {
        flex-direction: row-reverse;
        @include media-down(sp) {
            flex-wrap: wrap-reverse;
        }
    }
    .yearTtl {
        font-size: 25px;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 15px;
    }
    &02 {
        .tabBlock {
            display: none;
            &02 {
                .ttlYear {
                    margin-top: 80px;
                }
                .wp-block-buttons.mBottom {
                    margin-bottom: 30px;
                }
                @include media-down(sp) {
                    .ttlYear {
                        margin-top: 50px;
                    }
                    .wp-block-buttons.mBottom {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        #tabBlock01 {
            display: block;
        }

        &+.irList {
            margin-top: 150px;
        }
        .pagerLink {
            display: flex;
        }
        .listNews li {
            .label {
                display: block;
            }
        }
    }
}

.tab02 {
    display: flex;
    padding: 0 10px;
    border-bottom: 1px solid $color09;
    font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
	li {
		position: relative;
		display: inline-block;
        width: 20%;
        &.active {
            border: 1px solid $color09;
            border-bottom: none;
            &:after {
                position: absolute;
                width: 100%;
                height: 3px;
                content: '';
                background-color: $color-white;
                left: 0;
                bottom: -3px;
            }
        }
		a {
            display: block;
            text-align: center;
			width: 100%;
			font-size: 14px;
			font-weight: bold;
			color: $color-black;
			line-height: 1.3;
            text-decoration: none;
            padding: 16px 10px;
		}
	}
	@include media-up(sp) {
		li {
			a {
				transition: $transition-duration;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}

	@include media-down(sp) {
        width: 100%;
        padding: 0;
        flex-wrap: wrap;
        border-bottom: none;
        margin-bottom: 15px;
        li {
            width: 50%;
            text-align: center;
           &.active {
               border: none;
               background-color: $color09;
               a {
                   color: $color-white;
               }
           }
            a {
                padding: 8px;
            }
        }
    }
    @include media-down(374) {
        li a {
            font-size: 12px;
        }
    }
}
