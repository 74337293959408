// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
    overflow-x: hidden;
    background: #FFF;
    font-size: 10px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    overflow-x: hidden;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;
}

/**
 * Basic styles for links
 */
a {
    color: $link-color;
    text-decoration: none;

    @include hover {
        color: $link-color;
        text-decoration: underline;
    }
}
