// -----------------------------------------------------------------------------
// Button style
// -----------------------------------------------------------------------------
.blockBtn {
    text-align: center;
}

// a.btn,
.btn {
    a {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        padding: 14px 67px;
        font-size: 16px;
        font-weight: bold;
        color: $text-color;
        border: 1px solid $color01;
        border-radius: 5px;
        background: url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat $color-form-default-bg;
        background-position-x: calc(100% - 20px);
        background-position-y: 50%;
        text-align: center;
        line-height: 1.6;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        transition: $transition-duration;
        &:hover {
            text-decoration: none;
            color: $text-color;
        }
    }
    &.hover {
        a:hover {
            background-color: $color04;
            color: $color-white;
            background-image: image('common/icon_arrow_right_white.svg');
        }
    }
    &.btnBack {
        a {
            background-image: image('common/icon_arrow_left.svg');
            background-position: 20px 50%;
        }
        &.hover {
            a:hover {
                background-image: image('common/icon_arrow_left_white.svg');
            }
        }
    }
    &.btnCenter {
        margin: auto;
    }
}
.wp-block-buttons {
    text-align: center;
    &.align-left {
        text-align: left;
    }
    &.alignright,
    &.align-right {
        text-align: right;
    }
    .btn {
        a {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            padding: 14px 67px;
            font-size: 16px;
            font-weight: bold;
            color: $text-color;
            border: 1px solid $color01;
            border-radius: 5px;
            background: url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat $color-form-default-bg;
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
            text-align: center;
            line-height: 1.6;
            text-decoration: none;
            outline: none;
            &:hover {
                text-decoration: none;
                color: $text-color;
                cursor: pointer;
            }

            @include media-up(sp) {
                transition: $transition-duration;
                &:hover {
                    opacity: 0.7;
                }
            }
            @include media-down(sp) {
                padding: 10px 65px;
                background: url(#{$base-url}img/common/icon_arrow_right_white.svg) no-repeat $color04;
                background-position-x: calc(100% - 20px);
                background-position-y: 50%;
                color: $color-white;
                font-size: 15px;
            }
        }

        &.hover a {
            &:hover {
                opacity: 1;
                background-color: $color04;
                color: $color-white;
                background-image: image('common/icon_arrow_right_white.svg');
            }
        }
        &.white a {
            background: url(#{$base-url}img/common/icon_arrow_right_white.svg) no-repeat rgba(255, 255, 255, 0);
            background-position-x: calc(100% - 20px);
            background-position-y: 50%;
            color: $color-white;
            border: 1px solid $color-white;
            &:hover {
                opacity: 1;
                background-color: $color-white;
                color: $color04;
                background-image: image('common/icon_arrow_right_blue.svg');
            }
        }
        &.transparent a {
            background-color: transparent;
            color: $color-white;
            background-image: image('common/icon_arrow_right_white.svg');
        }
        &.btnDownload {
            a {
                background: url(#{$base-url}img/common/icon_pdf.svg), url(#{$base-url}img/common/icon_download.svg);
                background-repeat: no-repeat, no-repeat;
                background-position: 20px 50%, calc(100% - 20px) 50%;
            }
        }
        &.iconBlank {
            a {
                background: image('common/icon_blank.svg') no-repeat right 15px top 50%;
                background-size: 13px 13px;
                padding: 14px 45px 14px 25px;
                font-size: 14px;
                color: $text-color;
            }
            &.hover a {
                &:hover {
                    background-image: image('common/icon_blank_white.svg');
                    background-position: right 15px top 50%;
                    background-size: 13px 13px;
                }
            }
        }
    }
    &.alignright .wp-block-button:first-child {
        margin-left: auto;
        margin-right: 0;
    }
    & > .wp-block-button:last-child {
        margin-left: auto;
        margin-right: auto;
    }
    & > .wp-block-button.white:last-child {
        margin-left: 0;
        @include media-down(sp) {
            margin-left: auto;
        }
    }
    &.mTop {
        margin-top: 65px;
        @include media-down(sp) {
            margin-top: 35px;
        }
    }
}
.btnBlack {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 14px 57px;
    font-size: 16px;
    font-weight: bold;
    color: $color-white;
    border: 1px solid $color01;
    border-radius: 5px;
    background: image('common/icon_arrow_right_white.svg') no-repeat $color-black;
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    text-align: center;
    line-height: 1.6;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: $transition-duration;
    &.btnSubmit {
        margin-top: 47px;
    }
    &:hover {
        background-color: $color-form-default-bg;
        color: $text-color;
        background-image: image('common/icon_arrow_right.svg');
    }
    @include media-down(sp) {
        &.btnSubmit {
            margin-top: 25px;
        }
    }
}
