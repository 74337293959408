@charset "UTF-8";
/* stylelint-disable */
/* ******************************************************************

    --common.css--

    0. BoxModel change

    1. Common setting
        1-1. Reset styles
        1-2. Font styles
        1-3. General styles
        1-4. Text styles
        1-5. List styles
        1-6. CSS3 common styles

****************************************************************** */
/*==================================================================
    0. BoxModel change
===================================================================*/
/*==================================================================
    1. Common setting
===================================================================*/
/* ------------------------------------------------------------------
    1-1. Reset styles
-------------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
object,
iframe,
pre,
code,
p,
blockquote,
form,
fieldset,
legend,
table,
th,
td,
caption,
tbody,
tfoot,
thead,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
audio,
video,
canvas {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
menu {
  display: block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: none;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul,
ol,
menu {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: none;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

a img,
map a {
  border: none;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

embed {
  width: 100%;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img,
object,
embed {
  max-width: 100%;
  height: auto;
}

object,
embed {
  height: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* Font styles
-------------------------------------------------------------------*/
select,
input,
button,
textarea,
button {
  font-size: inherit;
}

table {
  font-size: inherit;
  font: 100%;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  line-height: 1;
}

/* General styles
-------------------------------------------------------------------*/
table {
  empty-cells: show;
}

input {
  line-height: 1;
}

form img,
input,
select {
  vertical-align: middle;
}

textarea {
  resize: none;
}

select {
  padding: 1px;
}

label {
  margin-right: 5px;
}

legend {
  display: none;
}

/* ------------------------------------------------------------------
    1-2. VisualFormattingModel styles
-------------------------------------------------------------------*/
.noDisplay {
  display: none;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inlineBlock {
  display: inline-block !important;
}

.static {
  position: static !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed !important;
}

.leftBox {
  float: left;
}

.rightBox {
  float: right;
}

.nofloat {
  float: none !important;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.clear {
  clear: both !important;
}

a.hover:hover,
input.hover:hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
}

/* ------------------------------------------------------------------
    1-3. BoxModel styles
-------------------------------------------------------------------*/
.auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mt0,
.mv0,
.ma0 {
  margin-top: 0 !important;
}

.mr0,
.mh0,
.ma0 {
  margin-right: 0 !important;
}

.mb0,
.mv0,
.ma0 {
  margin-bottom: 0 !important;
}

.ml0,
.mh0,
.ma0 {
  margin-left: 0 !important;
}

.pt0,
.pv0,
.pa0 {
  padding-top: 0 !important;
}

.pr0,
.ph0,
.pa0 {
  padding-right: 0 !important;
}

.pb0,
.pv0,
.pa0 {
  padding-bottom: 0 !important;
}

.pl0,
.ph0,
.pa0 {
  padding-left: 0 !important;
}

.mt5,
.mv5,
.ma5 {
  margin-top: 5px !important;
}

.mr5,
.mh5,
.ma5 {
  margin-right: 5px !important;
}

.mb5,
.mv5,
.ma5 {
  margin-bottom: 5px !important;
}

.ml5,
.mh5,
.ma5 {
  margin-left: 5px !important;
}

.pt5,
.pv5,
.pa5 {
  padding-top: 5px !important;
}

.pr5,
.ph5,
.pa5 {
  padding-right: 5px !important;
}

.pb5,
.pv5,
.pa5 {
  padding-bottom: 5px !important;
}

.pl5,
.ph5,
.pa5 {
  padding-left: 5px !important;
}

.mt10,
.mv10,
.ma10 {
  margin-top: 10px !important;
}

.mr10,
.mh10,
.ma10 {
  margin-right: 10px !important;
}

.mb10,
.mv10,
.ma10 {
  margin-bottom: 10px !important;
}

.ml10,
.mh10,
.ma10 {
  margin-left: 10px !important;
}

.pt10,
.pv10,
.pa10 {
  padding-top: 10px !important;
}

.pr10,
.ph10,
.pa10 {
  padding-right: 10px !important;
}

.pb10,
.pv10,
.pa10 {
  padding-bottom: 10px !important;
}

.pl10,
.ph10,
.pa10 {
  padding-left: 10px !important;
}

.mt20,
.mv20,
.ma20 {
  margin-top: 20px !important;
}

.mr20,
.mh20,
.ma20 {
  margin-right: 20px !important;
}

.mb20,
.mv20,
.ma20 {
  margin-bottom: 20px !important;
}

.ml20,
.mh20,
.ma20 {
  margin-left: 20px !important;
}

.pt20,
.pv20,
.pa20 {
  padding-top: 20px !important;
}

.pr20,
.ph20,
.pa20 {
  padding-right: 20px !important;
}

.pb20,
.pv20,
.pa20 {
  padding-bottom: 20px !important;
}

.pl20,
.ph20,
.pa20 {
  padding-left: 20px !important;
}

.mt30,
.mv30,
.ma30 {
  margin-top: 30px !important;
}

.mr30,
.mh30,
.ma30 {
  margin-right: 30px !important;
}

.mb30,
.mv30,
.ma30 {
  margin-bottom: 30px !important;
}

.ml30,
.mh30,
.ma30 {
  margin-left: 30px !important;
}

.pt30,
.pv30,
.pa30 {
  padding-top: 30px !important;
}

.pr30,
.ph30,
.pa30 {
  padding-right: 30px !important;
}

.pb30,
.pv30,
.pa30 {
  padding-bottom: 30px !important;
}

.pl30,
.ph30,
.pa30 {
  padding-left: 30px !important;
}

.mt40,
.mv40,
.ma40 {
  margin-top: 40px !important;
}

.mr40,
.mh40,
.ma40 {
  margin-right: 40px !important;
}

.mb40,
.mv40,
.ma40 {
  margin-bottom: 40px !important;
}

.ml40,
.mh40,
.ma40 {
  margin-left: 40px !important;
}

.pt40,
.pv40,
.pa40 {
  padding-top: 40px !important;
}

.pr40,
.ph40,
.pa40 {
  padding-right: 40px !important;
}

.pb40,
.pv40,
.pa40 {
  padding-bottom: 40px !important;
}

.pl40,
.ph40,
.pa40 {
  padding-left: 40px !important;
}

.bt0,
.ba0 {
  border-top: none !important;
}

.br0,
.ba0 {
  border-right: none !important;
}

.bb0,
.ba0 {
  border-bottom: none !important;
}

.bl0,
.ba0 {
  border-left: none !important;
}

/* ------------------------------------------------------------------
    1-4. Text styles
-------------------------------------------------------------------*/
strong,
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.note {
  margin-left: 1em;
  text-indent: -1em;
}

.f10 {
  font-size: 77%;
}

.f11 {
  font-size: 85%;
}

.f12 {
  font-size: 93%;
}

.f14 {
  font-size: 108%;
}

.f15 {
  font-size: 116%;
}

.f16 {
  font-size: 123.1%;
}

.f17 {
  font-size: 131%;
}

.f18 {
  font-size: 138.5%;
}

.f19 {
  font-size: 146.5%;
}

.f20 {
  font-size: 153.9%;
}

.f21 {
  font-size: 161.6%;
}

.f22 {
  font-size: 167%;
}

.f23 {
  font-size: 174%;
}

.f24 {
  font-size: 182%;
}

.f25 {
  font-size: 189%;
}

.f26 {
  font-size: 197%;
}

.red {
  color: #C00;
}

.white {
  color: #FFF;
}

.grayC {
  color: #CCC;
}

.gray9 {
  color: #999;
}

.gray6 {
  color: #666;
}

.gray3 {
  color: #333;
}

.black {
  color: #000;
}

.middle {
  vertical-align: middle !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.underline {
  text-decoration: underline !important;
}

/* ------------------------------------------------------------------
    1-5. List styles
-------------------------------------------------------------------*/
.disc {
  padding-left: 2em;
  list-style: disc;
}

.circle {
  padding-left: 2em;
  list-style: circle;
}

.decimal {
  padding-left: 2.4em;
  list-style: decimal;
}

/* ------------------------------------------------------------------
    1-6. CSS3 common styles
-------------------------------------------------------------------*/
.boxShadow {
  -webkit-box-shadow: 0 0 5px #DDD;
  box-shadow: 0 0 5px #DDD;
}

.textShadowW {
  text-shadow: 0 1px 0 #E7E7E7;
}

.textShadowB {
  text-shadow: 0 1px 0 #111;
}

.radius5 {
  border-radius: 5px;
}

.radius10 {
  border-radius: 10px;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  overflow-x: hidden;
  background: #FFF;
  font-size: 10px;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  word-wrap: break-word;
}

/**
 * Basic styles for links
 */
a {
  color: #36C;
  text-decoration: none;
}
a:hover {
  color: #36C;
  text-decoration: underline;
}

body {
  color: #333;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.231;
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", ヒラギノ角ゴシック Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, Verdana, Arial, sans-serif;
}
body#pageCompany {
  font-size: 1.6rem;
}
body#pageHome {
  font-size: 1.5rem;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#header {
  background: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s;
}
#header.active .navHeader {
  padding-top: 10px;
}
#header.active .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link,
#header.active #nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
}
#header .headerInner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}
#header #logo {
  display: block;
  max-width: 390px;
  line-height: 0;
}
#header .navHeader {
  width: calc(100% - 430px);
}
#header .topNav {
  transition: all 0.3s;
}
#header .topNav a {
  display: block;
  font-weight: bold;
  line-height: 1.3;
  color: #333;
}
#header .topNav a:hover {
  text-decoration: none;
}
#header .topNav select {
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_down.svg") no-repeat 100% 50%;
  background-size: 8px auto;
  font-weight: bold;
  padding: 0 20px 0 0;
  font-size: 13px;
  color: #000;
  cursor: pointer;
}
#header .topNav select::-ms-expand {
  display: none;
}
#header #nav a {
  display: block;
  font-weight: bold;
  text-decoration: none;
  line-height: 1.3125;
  color: #333;
  transition: all 0.3s;
}
#header #nav a:hover {
  opacity: 0.7;
}
#header .navToggle {
  display: none;
  border: none;
  outline: none;
  background: transparent;
  width: 22px;
  height: 74px;
  padding: 0;
}
#header .navToggle span {
  display: block;
  height: 2px;
  background: #080808;
  transition: all 0.3s;
}
#header .navToggle span:nth-child(2) {
  margin: 5px 0;
}
#header .navToggle.active span:nth-child(1) {
  transform: rotate(45deg) translateX(1px);
}
#header .navToggle.active span:nth-child(2) {
  display: none;
}
#header .navToggle.active span:nth-child(3) {
  transform: rotate(-45deg) translateX(2px);
}
#header .navWrap #mega-menu-wrap-primary {
  background: transparent !important;
  position: initial;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary {
  position: initial;
  text-align: right;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:first-child > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu > .mega-menu-column:nth-child(2) {
  width: calc(100% - 641px);
  max-width: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:first-child > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu > .mega-menu-column:nth-child(3) {
  margin-right: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:last-child > a.mega-menu-link {
  padding-right: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > ul.mega-sub-menu {
  padding: 32px 0 43px;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > ul.mega-sub-menu .mega-menu-item {
  width: auto;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link {
  padding: 21px 15px;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  height: auto;
  line-height: 1.3125;
  transition: 0.3s;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link:focus, #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link:hover {
  background: transparent !important;
  opacity: 0.7;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link > span.mega-indicator {
  display: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-toggle-on > a.mega-menu-link {
  background: transparent;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu {
  display: flex;
  justify-content: flex-start;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column {
  display: inline-block;
  width: auto;
  width: 33.33%;
  max-width: 190px;
  margin-right: 35px;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column ul.mega-sub-menu > .mega-menu-item {
  padding: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column ul.mega-sub-menu > .mega-menu-item .textwidget {
  margin-bottom: 12px;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child {
  max-width: none;
  margin-right: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column .mega-sub-menu {
  padding: 17px 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child, #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-792-0-2 {
  width: auto;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child .mega-sub-menu, #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-792-0-2 .mega-sub-menu {
  padding: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-792-0-1 {
  width: calc(100% - 641px);
  max-width: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child ul.mega-sub-menu {
  height: 100%;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child ul.mega-sub-menu > .mega-menu-item {
  height: 100%;
  padding: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child ul.mega-sub-menu > .mega-menu-item > h4.mega-block-title {
  height: 100%;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column a {
  display: inline-block;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  font-weight: bold;
  white-space: nowrap;
  transition: all 0.3s;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column a:hover {
  transform: translateX(10px);
  opacity: 1;
  text-decoration: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link, #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
  background: transparent;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu.mega-menu-item {
  min-width: 90px;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 163px;
  float: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row > ul.mega-sub-menu > li.mega-menu-columns-3-of-12 {
  float: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item {
  float: none;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item h4.mega-block-title {
  color: #333;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.54;
  padding: 14px 30px 14px 40px;
  width: 257px;
  border-right: 2px solid #E3E3E3;
  margin-right: 50px;
  display: flex;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item.widget_media_image {
  padding: 0;
  line-height: 0;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item.widget_media_image img {
  width: 267px;
}
#header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item.widget_media_image + .widget_text .mega-block-title {
  font-size: 16px;
  margin-top: 8px;
  font-weight: bold;
  padding: 0;
  border-right: none;
  margin-right: 0;
}
@media only screen and (min-width: 1000px) {
  #header #logo {
    transition: opacity 0.3s;
  }
  #header #logo:hover {
    opacity: 0.7;
  }
  #header .navHeader {
    display: block !important;
    padding-top: 20px;
    transition: all 0.3s;
  }
  #header .topNav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  #header .topNav a {
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid #D5D5D5;
  }
  #header .topNav li {
    border-right: 1px solid #D5D5D5;
    margin-right: 20px;
  }
  #header .topNav li:last-child {
    margin-right: 0;
    border-right: none;
  }
  #header #nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    float: right;
  }
  #header #nav > li {
    min-width: 90px;
  }
  #header #nav > li > a {
    position: relative;
    padding: 21px 15px;
    z-index: 2;
    transition: all 0.3s;
  }
  #header #nav > li:hover .subNav {
    top: 100%;
    visibility: visible !important;
    opacity: 1;
    z-index: 1;
  }
  #header #nav a {
    font-size: 16px;
  }
  #header #nav .subNav {
    position: absolute;
    width: 100%;
    padding: 32px 0 50px;
    font-family: "Hiragino Kaku Gothic", sans-serif;
    background-color: #F9F9F9;
    left: 0;
    top: calc(100% - 10px);
    visibility: hidden !important;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease;
  }
  #header #nav .subNav .headerInner {
    align-items: initial;
    min-height: 163px;
    align-content: center;
    justify-content: flex-start;
  }
  #header #nav .subNav .ttl {
    color: #333;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.54;
    padding: 14px 30px 14px 40px;
    width: 257px;
    border-right: 2px solid #E3E3E3;
    margin-right: 50px;
    display: flex;
  }
  #header #nav .subNav .detail {
    width: calc(100% - 325px);
    display: flex;
    justify-content: space-between;
    max-width: 1012px;
  }
  #header #nav .subNav .navChild {
    display: flex;
    width: calc(100% - 250px);
    max-width: 570px;
    padding: 17px 0;
  }
  #header #nav .subNav .txt {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
  }
  #header #nav .subNav .img {
    line-height: 0;
  }
  #header #nav .subNav .nav {
    width: 33.33%;
    margin-right: 35px;
  }
  #header #nav .subNav .nav a {
    line-height: 1.5;
    font-weight: bold;
    white-space: nowrap;
    transition: all 0.3s;
  }
  #header #nav .subNav .nav a:hover {
    transform: translateX(10px);
    opacity: 1;
  }
  #header #nav .subNav .nav li {
    margin-bottom: 12px;
  }
  #header #nav .subNav .nav li:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 1100px) {
  #header #logo {
    width: 300px;
  }
  #header .navHeader {
    width: calc(100% - 330px);
  }
}
@media only screen and (max-width: 999px) {
  #header .navToggle {
    display: block;
  }
  #header #logo {
    max-width: 320px;
    width: calc(100% - 50px);
  }
  #header .navHeader {
    display: none;
    position: absolute;
    width: 100%;
    height: calc(100vh - 74px);
    left: 0;
    top: 74px;
    background-color: #FBFBFB;
    padding: 10px 20px 50px;
    font-size: 20px;
    overflow-y: auto;
    flex-wrap: wrap-reverse;
    align-content: flex-end;
  }
  #header .navHeader a {
    padding: 12px 0;
  }
  #header .navHeader #nav {
    margin: 0 25px 20px 30px;
  }
  #header .navHeader #nav .subNav {
    display: none;
    padding-top: 15px;
  }
  #header .navHeader #nav .subNav .ttl {
    display: none;
  }
  #header .navHeader #nav .subNav .detail {
    width: 100%;
  }
  #header .navHeader #nav .subNav a {
    padding: 0;
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  #header .navHeader #nav .subNav .imgBox {
    display: none;
  }
  #header .navHeader #nav .subNav .headerInner {
    padding: 0;
  }
  #header .navHeader #nav > li > a {
    padding: 17px 35px 17px 0;
  }
  #header .navHeader #nav > li > a.iconPlus {
    position: relative;
  }
  #header .navHeader #nav > li > a.iconPlus:before {
    position: absolute;
    height: 21px;
    width: 1px;
    content: "";
    background-color: #707070;
    top: calc(50% - 10px);
    right: 15px;
    transition: all 0.3s;
  }
  #header .navHeader #nav > li > a.iconPlus:after {
    position: absolute;
    width: 21px;
    height: 1px;
    content: "";
    background-color: #707070;
    right: 5px;
    top: 50%;
    transition: all 0.3s;
  }
  #header .navHeader #nav > li > a.iconPlus.active:before {
    transform: rotate(90deg);
  }
  #header .topNav {
    border-top: 1px solid #DBDBDB;
    padding-top: 25px;
    margin: 0 30px;
    width: 100%;
  }
  #header .topNav .lang {
    position: absolute;
    top: 25px;
    right: 20px;
    font-size: 14px;
    z-index: 2;
  }
  #header .topNav .lang select {
    background-position: right 15px top 50%;
    padding: 10px 30px 10px 15px;
    min-width: 86px;
    text-align: center;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    text-align-last: center;
    text-align: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
  }
  #header .topNav a {
    font-size: 16px;
  }
  #header .navWrap {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    padding-top: 75px;
    margin: 0 15px;
    width: 100%;
  }
  #header .navWrap .topNav {
    width: 100%;
  }
  #header .navWrap #nav {
    width: 100%;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item {
    display: block !important;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link {
    text-align: left;
    display: block;
    position: relative;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link[aria-haspopup=true] {
    padding: 17px 35px 17px 15px;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link[aria-haspopup=true]:after {
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 1px;
    content: "";
    background-color: #707070;
    right: 11px;
    top: 50%;
    transition: all 0.3s;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link[aria-haspopup=true]:before {
    position: absolute;
    height: 21px;
    width: 1px;
    content: "";
    background-color: #707070;
    top: calc(50% - 10px);
    right: 15px;
    transition: all 0.3s;
  }
  #header .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link[aria-haspopup=true].active:before {
    transform: rotate(90deg);
  }
  #header .primary-menu-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary .mega-menu-toggle {
    display: none;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary .mega-menu-toggle + #mega-menu-primary {
    display: block;
    background: transparent;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column {
    margin-right: 0;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column .mega-sub-menu {
    padding: 0 0 0 0;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-792-0-2, #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column:first-child {
    display: none !important;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-792-0-1 {
    width: 100%;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column#mega-menu-829-0-2 {
    display: none;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column a {
    display: block;
    text-decoration: none;
    line-height: 1.3125;
    padding: 0;
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
    min-height: 0;
    padding: 0 15px;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu {
    position: relative;
    display: none;
    padding: 0;
    transform: translate(0, 0) !important;
    opacity: 1;
    background: transparent;
    visibility: visible !important;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > .mega-menu-row > .mega-sub-menu {
    flex-wrap: wrap;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-megamenu > ul.mega-sub-menu > .mega-menu-row > .mega-sub-menu > .mega-menu-column {
    width: 100% !important;
    max-width: none !important;
    float: none;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link {
    font-size: 20px;
    padding: 17px 35px 17px 15px !important;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link + .mega-sub-menu {
    padding-top: 15px;
  }
  #header .primary-menu-wrapper #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item:first-child > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu > .mega-menu-column:nth-child(3) {
    display: none;
  }
}

.spDisplay {
  display: none !important;
}
@media only screen and (max-width: 768px) {
  .spDisplay {
    display: block !important;
  }
}

.pcDisplay {
  display: block !important;
}
@media only screen and (max-width: 768px) {
  .pcDisplay {
    display: none !important;
  }
}

#main {
  width: 100%;
}

#contents {
  display: block;
  padding: 85px 0 150px;
}
@media only screen and (max-width: 768px) {
  #contents {
    padding: 45px 0 70px;
  }
}

.flexContents {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px !important;
}
.flexContents #main {
  width: calc(100% - 250px);
  float: left;
}
.flexContents #side {
  width: 190px;
  float: right;
}
@media only screen and (max-width: 768px) {
  .flexContents #main {
    width: 100%;
    float: none;
  }
  .flexContents #side {
    width: 100%;
    float: none;
    margin-top: 50px;
  }
}

.inner {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.innerMax {
  max-width: 1760px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.inner01 {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

body {
  padding-top: 110px;
}
@media only screen and (max-width: 999px) {
  body {
    padding-top: 74px;
  }
}

.iconPdf {
  display: inline-block;
  padding-right: 30px;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_pdf.svg") no-repeat 100% 0;
}

#footer {
  border-top: 1px solid #E3E3E3;
  padding: 50px 0;
  font-size: 13px;
}
#footer a {
  color: #333;
}
#footer .fNav01 {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 40px;
}
#footer .fNav01:after {
  position: absolute;
  width: 100%;
  max-width: 1400px;
  height: 1px;
  content: "";
  background-color: #E3E3E3;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
#footer .fNav01 .ttl {
  font-size: 14px;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 7px;
}
#footer .fNav01 .subNav a {
  line-height: 2.385;
}
#footer .fNav01 .img {
  line-height: 0;
  margin-top: 25px;
}
#footer .fNav01 .col06 .img a {
  display: inline-block;
}
#footer .fNav02 .fLogo {
  line-height: 0;
  width: 255px;
}
#footer .fNav02 .copyright {
  font-family: "Hiragino Kaku Gothic Pro", sans-serif;
  color: #808080;
  line-height: 2.15;
  width: calc(100% - 300px);
}
#footer .btnContact {
  display: block;
  font-size: 14px;
  line-height: 2;
  padding: 12px 10px 11px;
  font-weight: bold;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  text-align: center;
  transition: opacity 0.3s;
}
#footer .btnContact:hover {
  text-decoration: none;
  opacity: 0.7;
}
#footer .footer_title {
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 7px;
}
#footer .footer_title + div > * {
  padding-bottom: 20px;
}
#footer .menu a {
  line-height: 2.385;
}
@media only screen and (min-width: 769px) {
  #footer .fNav01 .inner {
    display: flex;
    flex-wrap: wrap;
  }
  #footer .fNav01 .col {
    width: calc(100%/6);
    padding-right: 15px;
  }
  #footer .fNav01 .col:last-child {
    padding-right: 0;
  }
  #footer .fNav01 .col01 {
    width: calc(163/1000*100%);
    order: 1;
  }
  #footer .fNav01 .col02 {
    width: calc(148/1000*100%);
    order: 2;
  }
  #footer .fNav01 .col03 {
    width: calc(125/1000*100%);
    order: 3;
  }
  #footer .fNav01 .col04 {
    width: calc(160/1000*100%);
    order: 4;
  }
  #footer .fNav01 .col05 {
    width: calc(210/1000*100%);
    order: 5;
  }
  #footer .fNav01 .col05 .footer_title {
    display: none;
  }
  #footer .fNav01 .col05 .ttl {
    margin-bottom: 10px;
  }
  #footer .fNav01 .col05 .ttl:last-child {
    margin-bottom: 0;
  }
  #footer .fNav01 .col05 li {
    font-size: 16px;
    font-weight: bold;
    line-height: 2;
    margin-bottom: 5px;
  }
  #footer .fNav01 .col06 {
    width: calc(194/1000*100%);
    padding-top: 6px;
    order: 6;
  }
  #footer .fNav01 .col06 .img a {
    transition: 0.3s;
  }
  #footer .fNav01 .col06 .img a:hover {
    opacity: 0.7;
  }
  #footer .fNav01 .col07 {
    margin-top: 10px;
    order: 7;
  }
  #footer .fNav02 .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media only screen and (max-width: 768px) {
  #footer {
    padding: 30px 0;
  }
  #footer a:hover {
    text-decoration: none;
  }
  #footer .fNav01 {
    padding: 0 10px 25px;
  }
  #footer .fNav01 .subNav {
    display: none;
    padding-bottom: 10px;
  }
  #footer .fNav01 .img {
    text-align: center;
    margin-top: 17px;
  }
  #footer .fNav01 .col {
    border-bottom: 1px solid #707070;
  }
  #footer .fNav01 .col07 {
    border-bottom: 1px solid #707070;
  }
  #footer .fNav01 .col07 > .footer_title, #footer .fNav01 .col07 > .ttl {
    position: relative;
    margin-bottom: 0;
    padding: 14px 35px 13px 0;
    font-size: 16px;
  }
  #footer .fNav01 .col07 > .footer_title span, #footer .fNav01 .col07 > .ttl span {
    display: block;
    padding: 14px 35px 13px 0;
    font-size: 16px;
  }
  #footer .fNav01 .col07 > .footer_title:after, #footer .fNav01 .col07 > .ttl:after {
    position: absolute;
    width: 21px;
    height: 1px;
    content: "";
    background-color: #707070;
    right: 5px;
    top: 50%;
  }
  #footer .fNav01 .col07 > .footer_title:before, #footer .fNav01 .col07 > .ttl:before {
    position: absolute;
    height: 21px;
    width: 1px;
    content: "";
    background-color: #707070;
    top: calc(50% - 10px);
    right: 15px;
    transition: all 0.3s;
  }
  #footer .fNav01 .col07 > .footer_title.active:before, #footer .fNav01 .col07 > .ttl.active:before {
    transform: rotate(90deg);
  }
  #footer .fNav01 .col > .footer_title, #footer .fNav01 .col > .ttl {
    position: relative;
    margin-bottom: 0;
    padding: 14px 35px 13px 0;
    font-size: 16px;
  }
  #footer .fNav01 .col > .footer_title a, #footer .fNav01 .col > .ttl a {
    display: block;
    padding: 14px 35px 13px 0;
    font-size: 16px;
  }
  #footer .fNav01 .col > .footer_title:after, #footer .fNav01 .col > .ttl:after {
    position: absolute;
    width: 21px;
    height: 1px;
    content: "";
    background-color: #707070;
    right: 5px;
    top: 50%;
  }
  #footer .fNav01 .col > .footer_title:before, #footer .fNav01 .col > .ttl:before {
    position: absolute;
    height: 21px;
    width: 1px;
    content: "";
    background-color: #707070;
    top: calc(50% - 10px);
    right: 15px;
    transition: all 0.3s;
  }
  #footer .fNav01 .col > .footer_title.active:before, #footer .fNav01 .col > .ttl.active:before {
    transform: rotate(90deg);
  }
  #footer .fNav01 .col > .footer_title.noChild:before, #footer .fNav01 .col > .footer_title.noChild:after, #footer .fNav01 .col > .ttl.noChild:before, #footer .fNav01 .col > .ttl.noChild:after {
    display: none;
  }
  #footer .fNav01 .col05 {
    border-bottom: none;
  }
  #footer .fNav01 .col05 .footer_title {
    padding: 0;
  }
  #footer .fNav01 .col05 .footer_title:before, #footer .fNav01 .col05 .footer_title:after {
    display: none;
  }
  #footer .fNav01 .col06 {
    border-bottom: none;
  }
  #footer .fNav01 .menu {
    display: none;
  }
  #footer .fNav01 #menu-footer-column5,
#footer .fNav01 .tagLst {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #707070;
    padding: 20px 0 14px;
  }
  #footer .fNav01 #menu-footer-column5 li,
#footer .fNav01 #menu-footer-column5 .ttl,
#footer .fNav01 .tagLst li,
#footer .fNav01 .tagLst .ttl {
    position: relative;
    font-weight: 500;
    margin-right: 23px;
    padding-right: 25px;
    margin-bottom: 5px;
  }
  #footer .fNav01 #menu-footer-column5 li:last-child,
#footer .fNav01 #menu-footer-column5 .ttl:last-child,
#footer .fNav01 .tagLst li:last-child,
#footer .fNav01 .tagLst .ttl:last-child {
    margin-right: 0;
  }
  #footer .fNav01 #menu-footer-column5 li:after,
#footer .fNav01 #menu-footer-column5 .ttl:after,
#footer .fNav01 .tagLst li:after,
#footer .fNav01 .tagLst .ttl:after {
    position: absolute;
    height: 15px;
    width: 1px;
    content: "";
    background-color: #000;
    right: 0;
    top: calc(50% - 7px);
  }
  #footer .fNav01 #menu-footer-column5 li:first-child,
#footer .fNav01 #menu-footer-column5 .ttl:first-child,
#footer .fNav01 .tagLst li:first-child,
#footer .fNav01 .tagLst .ttl:first-child {
    display: none;
  }
  #footer .fNav02 {
    text-align: center;
  }
  #footer .fNav02 .fLogo {
    width: 100%;
    margin-bottom: 17px;
  }
  #footer .fNav02 .fLogo img {
    max-width: 255px;
  }
  #footer .fNav02 .copyright {
    width: 100%;
    font-size: 11px;
    line-height: 1.55;
  }
  #footer .btnContact {
    max-width: 251px;
    margin: 0 auto;
    padding: 12px 45px 11px;
    color: #FFF;
    border-radius: 5px;
    background: #00263E url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white02.svg") no-repeat right 28px top 50%;
  }
}

.txtN {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 2;
}
.txtN.mBottom {
  margin-bottom: 90px;
}
@media only screen and (max-width: 768px) {
  .txtN {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .txtN.mBottom {
    margin-bottom: 45px;
  }
}

.txtSign {
  margin-bottom: 35px;
  padding-top: 38px;
  font-size: 14px;
  line-height: 1.7;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .txtSign {
    padding-top: 30px;
  }
}

.space01 {
  margin-bottom: 125px;
}
@media only screen and (max-width: 768px) {
  .space01 {
    margin-bottom: 65px;
  }
}

.space02 {
  margin-bottom: 45px;
}
@media only screen and (max-width: 768px) {
  .space02 {
    margin-bottom: 30px;
  }
}

.space03 {
  margin-bottom: 70px;
}
@media only screen and (max-width: 768px) {
  .space03 {
    margin-bottom: 40px;
  }
}

.space04 {
  margin-bottom: 150px;
}
@media only screen and (max-width: 768px) {
  .space04 {
    margin-bottom: 80px;
  }
}

.txtLink a {
  color: #1872B4;
}

.anchorList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  margin: 0 auto;
  justify-content: center;
  background-color: #F1F1F1;
  margin-bottom: 70px;
}
.anchorList li {
  position: relative;
  padding: 3px 20px;
}
.anchorList li:after {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 13px;
  background-color: #000;
  transform: translateY(-50%);
  content: "";
}
.anchorList li:last-child:after {
  display: none;
}
.anchorList a {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: 1.3;
  text-decoration: none;
  transition: 0.3s;
}
.anchorList a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 768px) {
  .anchorList {
    margin-bottom: 35px;
    padding: 15px 0;
    justify-content: flex-start;
  }
}

.breadcrumb {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumb li {
  line-height: 1.33;
}
.breadcrumb li + li:before {
  padding: 0 4px;
  font-size: 12px;
  content: "＞";
}
.breadcrumb a {
  color: #333;
}

.breadcrumbWrap {
  background-color: #F1F1F1;
  padding: 12px 0;
}
.breadcrumbWrap .breadcrumb {
  justify-content: flex-end;
}
.breadcrumbWrap .breadcrumb li {
  font-size: 12px;
}

.blockBtn {
  text-align: center;
}

.btn a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 14px 67px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat #FFF;
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  text-align: center;
  line-height: 1.6;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
.btn a:hover {
  text-decoration: none;
  color: #333;
}
.btn.hover a:hover {
  background-color: #00263E;
  color: #FFF;
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg");
}
.btn.btnBack a {
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_left.svg");
  background-position: 20px 50%;
}
.btn.btnBack.hover a:hover {
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_left_white.svg");
}
.btn.btnCenter {
  margin: auto;
}

.wp-block-buttons {
  text-align: center;
}
.wp-block-buttons.align-left {
  text-align: left;
}
.wp-block-buttons.alignright, .wp-block-buttons.align-right {
  text-align: right;
}
.wp-block-buttons .btn a {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 14px 67px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat #FFF;
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  text-align: center;
  line-height: 1.6;
  text-decoration: none;
  outline: none;
}
.wp-block-buttons .btn a:hover {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
@media only screen and (min-width: 769px) {
  .wp-block-buttons .btn a {
    transition: 0.3s;
  }
  .wp-block-buttons .btn a:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 768px) {
  .wp-block-buttons .btn a {
    padding: 10px 65px;
    background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg) no-repeat #00263E;
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    color: #FFF;
    font-size: 15px;
  }
}
.wp-block-buttons .btn.hover a:hover {
  opacity: 1;
  background-color: #00263E;
  color: #FFF;
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg");
}
.wp-block-buttons .btn.white a {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg) no-repeat rgba(255, 255, 255, 0);
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  color: #FFF;
  border: 1px solid #FFF;
}
.wp-block-buttons .btn.white a:hover {
  opacity: 1;
  background-color: #FFF;
  color: #00263E;
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_blue.svg");
}
.wp-block-buttons .btn.transparent a {
  background-color: transparent;
  color: #FFF;
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg");
}
.wp-block-buttons .btn.btnDownload a {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_pdf.svg), url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_download.svg);
  background-repeat: no-repeat, no-repeat;
  background-position: 20px 50%, calc(100% - 20px) 50%;
}
.wp-block-buttons .btn.iconBlank a {
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_blank.svg") no-repeat right 15px top 50%;
  background-size: 13px 13px;
  padding: 14px 45px 14px 25px;
  font-size: 14px;
  color: #333;
}
.wp-block-buttons .btn.iconBlank.hover a:hover {
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_blank_white.svg");
  background-position: right 15px top 50%;
  background-size: 13px 13px;
}
.wp-block-buttons.alignright .wp-block-button:first-child {
  margin-left: auto;
  margin-right: 0;
}
.wp-block-buttons > .wp-block-button:last-child {
  margin-left: auto;
  margin-right: auto;
}
.wp-block-buttons > .wp-block-button.white:last-child {
  margin-left: 0;
}
@media only screen and (max-width: 768px) {
  .wp-block-buttons > .wp-block-button.white:last-child {
    margin-left: auto;
  }
}
.wp-block-buttons.mTop {
  margin-top: 65px;
}
@media only screen and (max-width: 768px) {
  .wp-block-buttons.mTop {
    margin-top: 35px;
  }
}

.btnBlack {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 14px 57px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg") no-repeat #000;
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  text-align: center;
  line-height: 1.6;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}
.btnBlack.btnSubmit {
  margin-top: 47px;
}
.btnBlack:hover {
  background-color: #FFF;
  color: #333;
  background-image: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg");
}
@media only screen and (max-width: 768px) {
  .btnBlack.btnSubmit {
    margin-top: 25px;
  }
}

input,
select,
textarea {
  font-size: 16px;
  line-height: 1.3125;
  padding: 10px;
  border: 1px solid #B4B4B4;
  border-radius: 5px;
  width: 100%;
  outline: none;
  background-color: #FFF;
}
input.halfWidth,
select.halfWidth,
textarea.halfWidth {
  max-width: 271px;
}
input::placeholder,
select::placeholder,
textarea::placeholder {
  color: #949494;
}
@media only screen and (max-width: 768px) {
  input.halfWidth,
select.halfWidth,
textarea.halfWidth {
    max-width: none;
  }
}

input {
  max-width: 600px;
}

.checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  font-size: 15px;
  line-height: 1.33;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: 60px;
}
.checkbox:last-child {
  margin-right: 0;
}
.checkbox.agree {
  font-weight: bold;
}
.checkbox.agree.error .label {
  border-color: #FF2E2E;
}
.checkbox ~ .checkbox {
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .checkbox ~ .checkbox {
    margin-top: 10px;
  }
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input:checked ~ .label:after {
  display: block;
}
.checkbox .label {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #FFF;
  border: 1px solid #B4B4B4;
  border-radius: 5px;
}
.checkbox .label:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select {
  padding-right: 35px;
  appearance: none;
  background: #FFF url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_select.svg") no-repeat right 18px top 50%;
  background-size: 9px auto;
}
select::-ms-expand {
  display: none;
}

.contactForm .noteList {
  font-size: 14px;
  line-height: 1.357;
  margin-top: 10px;
}

.headline1 {
  font-size: 32px;
  font-weight: bold;
  color: #000;
  line-height: 1.3;
}
@media only screen and (max-width: 768px) {
  .headline1 {
    font-size: 25px;
    line-height: 1.5;
  }
}

.headline2 {
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.4;
}
@media only screen and (max-width: 768px) {
  .headline2 {
    margin-bottom: 20px;
    font-size: 22px;
  }
}

.headline3 {
  position: relative;
  margin-bottom: 50px;
  padding: 0 0 23px 2px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 35px;
  font-weight: bold;
  line-height: 1.4;
}
.headline3:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  width: 100px;
  height: 1px;
  background-color: #606060;
}
@media only screen and (max-width: 768px) {
  .headline3 {
    margin-bottom: 30px;
    padding: 0 0 10px;
    font-size: 22px;
  }
}

.headline4 {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 25px;
  border-bottom: 1px solid #E3E3E3;
}
@media only screen and (max-width: 768px) {
  .headline4 {
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-size: 20px;
  }
}

.headline5 {
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.35;
  padding-bottom: 25px;
  border-bottom: 1px solid #E3E3E3;
  margin-bottom: 40px;
}
.headline5.noBorder {
  padding-bottom: 0;
  border-bottom: none;
}
@media only screen and (max-width: 768px) {
  .headline5 {
    font-size: 20px;
  }
}

.headline6 {
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3125;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 8px;
  margin-bottom: 15px;
}
.headline6:after {
  position: absolute;
  width: 60px;
  height: 1px;
  content: "";
  background-color: #606060;
  bottom: -1px;
  left: 0;
}

.headline7 {
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.325;
  margin-bottom: 95px;
}
@media only screen and (max-width: 768px) {
  .headline7 {
    font-size: 25px;
    margin-bottom: 45px;
  }
}

.headline8 {
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.75;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 11px;
  margin-bottom: 26px;
}
.headline8:after {
  position: absolute;
  width: 100px;
  height: 1px;
  content: "";
  background-color: #606060;
  bottom: -1px;
  left: 0;
}
.headline8.noBorder {
  border-bottom: none;
  padding-bottom: 0;
}
.headline8.noBorder:after {
  display: none;
}
@media only screen and (max-width: 768px) {
  .headline8 {
    font-size: 17px;
  }
}

.headline9 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 1.5;
}
@media only screen and (max-width: 768px) {
  .headline9 {
    font-size: 17px;
    margin-bottom: 15px;
  }
}

.headline4Wrap {
  margin-bottom: 0 !important;
}
.headline4Wrap .headline4 {
  border-bottom: none;
}
.headline4Wrap .readMore {
  text-align: right;
}
.headline4Wrap .readMore a {
  display: inline-block;
  font-size: 15px;
  line-height: 2.2;
  color: #333;
  text-decoration: none;
  transition: 0.3s;
}
.headline4Wrap .readMore a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .headline4Wrap {
    flex-wrap: nowrap !important;
  }
}

.headline10 {
  font-size: 49px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 0.7755;
  color: #CBCBCB;
  text-align: center;
}
.headline10 span {
  display: block;
  font-size: 29px;
  line-height: 1.31;
  margin-top: 6px;
}
@media only screen and (max-width: 768px) {
  .headline10 {
    font-size: 35px;
    margin-bottom: 15px;
  }
  .headline10 span {
    font-size: 20px;
  }
}

#keyv.keyv {
  position: relative;
  height: 370px;
}
#keyv.keyv .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding-top: 60px;
  padding-bottom: 20px;
}
#keyv.keyv .ttl {
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.325;
  color: #FFF;
  margin-bottom: 15px;
}
#keyv.keyv .breadcrumb {
  color: #FFF;
}
#keyv.keyv .breadcrumb li {
  margin: 3px 0;
  font-size: 16px;
}
#keyv.keyv .breadcrumb a {
  color: #FFF;
}
@media only screen and (max-width: 768px) {
  #keyv.keyv {
    height: 200px;
  }
  #keyv.keyv .inner {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  #keyv.keyv .ttl {
    margin-bottom: 10px;
    font-size: 25px;
  }
  #keyv.keyv .breadcrumb li {
    font-size: 13px;
  }
}
#keyv .keyvInner {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}
#keyv .keyvTopCarousel {
  position: relative;
  margin-bottom: 70px;
}
#keyv .keyvTopCarousel .keyvContent {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
  color: #FFF;
  transform: translateY(-50%);
}
#keyv .keyvTopCarousel .keyvContent .keyvInner {
  width: calc(100% - 40px);
}
#keyv .keyvTopCarousel .ttl {
  font-family: "Trajan Pro";
  margin-bottom: 20px;
  font-size: 46px;
  line-height: 1.48;
}
#keyv .keyvTopCarousel .txtIntro {
  margin-bottom: 45px;
  font-size: 16px;
  font-weight: bold;
  line-height: 2.125;
}
#keyv .keyvTopCarousel .swiper-slide .img {
  position: relative;
  line-height: 0;
  height: 649px;
  overflow: hidden;
}
#keyv .keyvTopCarousel .swiper-slide img {
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#keyv .keyvTopCarousel .swiper-pagination {
  position: relative;
  line-height: 0;
  margin-top: 27px;
  width: 100%;
}
#keyv .keyvTopCarousel .swiper-pagination-bullet {
  margin-right: 22px;
  outline: none;
}
#keyv .keyvTopCarousel .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
#keyv .keyvTopCarousel .swiper-pagination-bullet-active {
  background-color: #4D4D4D;
}
#keyv .keyvTopCarousel .btn {
  font-family: "YuGothic";
  font-size: 16px;
  padding: 13px 60px 13px 26px;
  background-position: right 30px top 50%;
}
@media only screen and (max-width: 768px) {
  #keyv .keyvTopCarousel {
    margin-bottom: 35px;
  }
  #keyv .keyvTopCarousel .keyvContent {
    bottom: 45px;
    top: auto;
    transform: translateY(0);
  }
  #keyv .keyvTopCarousel .ttl {
    font-size: 26px;
    line-height: 1.54;
  }
  #keyv .keyvTopCarousel .txtIntro {
    margin-bottom: 50px;
    font-size: 14px;
    line-height: 1.714;
  }
  #keyv .keyvTopCarousel .swiper-slide .img {
    height: 606px;
    position: relative;
  }
  #keyv .keyvTopCarousel .swiper-slide img {
    width: 100%;
    height: auto;
  }
  #keyv .keyvTopCarousel .keyvInner {
    width: calc(100vw - 39px);
    padding: 0 19px 0 20px;
  }
  #keyv .keyvTopCarousel .btn {
    font-size: 15px;
    padding: 12px 34px 12px 12px;
    background-position: right 14px top 50%;
  }
  #keyv .keyvTopCarousel .swiper-pagination {
    display: none;
  }
}

.n2-ss-slider {
  margin-bottom: 70px;
}
.n2-ss-slider .btn.transparent a {
  font-size: 16px !important;
  padding: 14px 60px 14px 26px !important;
  background-color: transparent !important;
  color: #FFF !important;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg") no-repeat right 30px top 50% !important;
  border: 1px solid #e3e3e3 !important;
  transition: all 0.3s;
}
.n2-ss-slider .btn.transparent a:hover {
  opacity: 1;
  background-color: #00263E !important;
  border-color: #00263E !important;
}
.n2-ss-slider .btn.transparent.iconBlank a {
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_blank_white.svg") no-repeat right 22px top 50% !important;
  background-size: 13px 13px;
}
.n2-ss-slider .btn.transparent.iconBlank a:hover {
  opacity: 1;
  background-color: #00263E !important;
  border-color: #00263E !important;
}
.n2-ss-slider .n2-ss-img-wrapper,
.n2-ss-slider .ttl {
  margin-bottom: 33px !important;
  font-size: 46px !important;
}
.n2-ss-slider .n2-ss-img-wrapper .n2-font-38928b24d0c5cc267c0574126be47991-hover,
.n2-ss-slider .ttl .n2-font-38928b24d0c5cc267c0574126be47991-hover {
  font-size: 100% !important;
}
.n2-ss-slider .txtIntro {
  margin-bottom: 45px !important;
  font-size: 16px !important;
}
.n2-ss-slider .txtIntro.mb0 {
  margin-bottom: 0 !important;
}
.n2-ss-slider .n2-ss-layer[data-sstype=content] {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
  color: #FFF;
  transform: translateY(-50%);
}
.n2-ss-slider .n2-ss-section-main-content {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px !important;
}
.n2-ss-slider .n2-ss-slider-2,
.n2-ss-slider .n2-ss-layers-container,
.n2-ss-slider .n2-ss-slide {
  height: 649px !important;
}
.n2-ss-slider [data-hash=a01b6d666a4bce684466af3f707b1047] {
  background-position: right 25% bottom 0 !important;
}
.n2-ss-slider .n2-ss-layer {
  padding-right: 44%;
}
@media only screen and (max-width: 768px) {
  .n2-ss-slider {
    margin-bottom: 35px;
  }
  .n2-ss-slider .n2-ss-slider-2,
.n2-ss-slider .n2-ss-layers-container,
.n2-ss-slider .n2-ss-slide {
    height: 606px !important;
  }
  .n2-ss-slider .ttl {
    font-size: 26px !important;
    line-height: 1.54 !important;
  }
  .n2-ss-slider .txtIntro {
    margin-bottom: 50px !important;
    font-size: 14px !important;
    line-height: 1.714 !important;
  }
  .n2-ss-slider .txtIntro.hasImg {
    position: relative;
    padding-right: 40%;
  }
  .n2-ss-slider .btn.transparent a {
    font-size: 15px !important;
    padding: 12px 34px 12px 12px !important;
    background-position: right 14px top 50% !important;
  }
  .n2-ss-slider .n2-ss-control-bullet {
    display: none;
  }
  .n2-ss-slider [data-hash=c59b886d1f0387d34f15154dc269fe6f] {
    background-image: url(/wp-content/uploads/2020/09/img_slide01_sp.jpg) !important;
  }
  .n2-ss-slider [data-hash=a01b6d666a4bce684466af3f707b1047] {
    background-image: url(/wp-content/themes/twentytwenty-child/assets/img/home/img_slide02_sp.jpg) !important;
    background-position: 0 0 !important;
    background-size: cover;
  }
  .n2-ss-slider .n2-ss-section-main-content {
    justify-content: flex-end !important;
    padding-bottom: 45px !important;
  }
  .n2-ss-slider .n2-ss-layer {
    padding-right: 0;
  }
  .n2-ss-slider .n2-ss-img-wrapper img {
    max-width: 334px !important;
  }
  .n2-ss-slider .prStyle {
    padding-right: 40%;
  }
}

div#n2-ss-2 .n2-ss-layer-content {
  background-size: 25% auto !important;
  background-position: right 20px top 50% !important;
}

@media only screen and (min-width: 1100px) {
  div#n2-ss-2 .n2-ss-layer-content {
    background-size: 280px auto !important;
  }
}
@media only screen and (max-width: 768px) {
  div#n2-ss-2 .n2-ss-layer-content {
    background-position: right 20px bottom 45px !important;
    background-size: 30% auto !important;
  }
}
.pagerLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}
.pagerLink span, .pagerLink a {
  display: block;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #E3E3E3;
  padding: 8px;
  min-width: 35px;
  text-align: center;
  margin: 0 5px 5px;
}
.pagerLink span.current, .pagerLink a.current {
  color: #FFF;
  background-color: #606060;
  border-color: #606060;
}
.pagerLink span.prevLink, .pagerLink a.prevLink {
  padding-left: 35px;
  padding-right: 22px;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_back.svg") no-repeat 15px 50%;
  background-size: 7px auto;
  margin-left: 0;
}
.pagerLink span.nextLink, .pagerLink a.nextLink {
  padding-right: 35px;
  padding-left: 22px;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_next.svg") no-repeat right 15px top 50%;
  background-size: 7px auto;
  margin-right: 0;
}
.pagerLink a {
  cursor: pointer;
  transition: opacity 0.3s;
}
.pagerLink a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 1024px) {
  .pagerLink span, .pagerLink a {
    padding: 5px;
    min-width: 26px;
    font-size: 12px;
    margin: 0 3px 3px;
    border-radius: 2px;
  }
  .pagerLink span.prevLink, .pagerLink a.prevLink {
    padding-left: 25px;
    padding-right: 10px;
    background-position: 10px 50%;
    background-size: 4px auto;
  }
  .pagerLink span.nextLink, .pagerLink a.nextLink {
    padding-right: 25px;
    padding-left: 10px;
    background-position: right 10px top 50%;
    background-size: 4px auto;
  }
}

.tabArea {
  margin-bottom: 30px;
}
.tabArea .headline1 {
  min-width: 140px;
  margin-bottom: 0;
}
.tabArea .headTab {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  padding-right: 16px;
}
.tabArea .headTab.yearBlock {
  margin-top: 30px;
  background-color: #F1F1F1;
}
.tabArea .headTab.yearBlock .headline1 {
  display: none;
}
.tabArea .headTab.yearBlock .yearList {
  max-width: 865px;
  padding: 15px 20px;
  margin: 0 auto;
  justify-content: flex-start;
}
.tabArea .headTab.yearBlock .yearList li {
  padding: 3px 20px;
}
.tabArea .headTab.yearBlock .yearList li a {
  padding: 0;
  border-bottom: none;
  font-weight: 500;
}
.tabArea .headTab.yearBlock .yearList li a:after {
  display: none;
}
.tabArea .headTab.yearBlock .yearList li.active a {
  font-weight: bold;
}
@media only screen and (max-width: 1024px) {
  .tabArea .headTab {
    padding-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  .tabArea {
    margin-bottom: 20px;
  }
  .tabArea .headline1 {
    margin-bottom: 20px;
  }
  .tabArea .headTab {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .tabArea .headTab.yearBlock {
    margin-bottom: 30px;
  }
}

.tab {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.tab li {
  position: relative;
  display: inline-block;
  padding: 0 20px;
}
.tab li:after {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
  height: 13px;
  background-color: #000;
  transform: translateY(-50%);
  content: "";
}
.tab li:last-child {
  border-right: 0;
}
.tab li:last-child:after {
  content: none;
}
.tab li a {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: 1.3;
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  .tab {
    margin-bottom: 15px;
  }
  .tab li a {
    padding: 15px 0;
    border-bottom: 2px solid transparent;
    transition: 0.3s;
  }
  .tab li a:hover {
    opacity: 0.7;
  }
  .tab li.active a {
    border-bottom-color: #00263E;
  }
}
@media only screen and (max-width: 768px) {
  .tab {
    width: calc(100% - 20px);
    margin: 0 10px;
    padding: 6px 12px 16px 12px;
    background-color: #EFEFEF;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .tab li {
    padding: 0 15px;
    text-align: center;
    margin-top: 10px;
  }
  .tab.yearList {
    width: 100%;
    padding: 6px 12px 16px 12px !important;
  }
  .tab.yearList li {
    padding: 0 15px !important;
  }
}
@media only screen and (max-width: 374px) {
  .tab li {
    padding: 0 10px;
  }
  .tab li a {
    font-size: 12px;
  }
  .tab.yearList li {
    padding: 0 10px !important;
  }
}

.tabContents {
  margin: 0 10px;
}
.tabContents .tabBox {
  display: none;
}
.tabContents #tab01 {
  display: block;
}
.tabContents .pagerLink {
  display: none;
}
.tabContents .listNews.listReverse li {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 768px) {
  .tabContents .listNews.listReverse li {
    flex-wrap: wrap-reverse;
  }
}
.tabContents .yearTtl {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 15px;
}
.tabContents02 .tabBlock {
  display: none;
}
.tabContents02 .tabBlock02 .ttlYear {
  margin-top: 80px;
}
.tabContents02 .tabBlock02 .wp-block-buttons.mBottom {
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  .tabContents02 .tabBlock02 .ttlYear {
    margin-top: 50px;
  }
  .tabContents02 .tabBlock02 .wp-block-buttons.mBottom {
    margin-bottom: 20px;
  }
}
.tabContents02 #tabBlock01 {
  display: block;
}
.tabContents02 + .irList {
  margin-top: 150px;
}
.tabContents02 .pagerLink {
  display: flex;
}
.tabContents02 .listNews li .label {
  display: block;
}

.tab02 {
  display: flex;
  padding: 0 10px;
  border-bottom: 1px solid #707070;
  font-family: "Yu Gothic", "YuGothic", "Yu Gothic Medium", sans-serif;
}
.tab02 li {
  position: relative;
  display: inline-block;
  width: 20%;
}
.tab02 li.active {
  border: 1px solid #707070;
  border-bottom: none;
}
.tab02 li.active:after {
  position: absolute;
  width: 100%;
  height: 3px;
  content: "";
  background-color: #FFF;
  left: 0;
  bottom: -3px;
}
.tab02 li a {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: 1.3;
  text-decoration: none;
  padding: 16px 10px;
}
@media only screen and (min-width: 769px) {
  .tab02 li a {
    transition: 0.3s;
  }
  .tab02 li a:hover {
    opacity: 0.7;
  }
}
@media only screen and (max-width: 768px) {
  .tab02 {
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
    border-bottom: none;
    margin-bottom: 15px;
  }
  .tab02 li {
    width: 50%;
    text-align: center;
  }
  .tab02 li.active {
    border: none;
    background-color: #707070;
  }
  .tab02 li.active a {
    color: #FFF;
  }
  .tab02 li a {
    padding: 8px;
  }
}
@media only screen and (max-width: 374px) {
  .tab02 li a {
    font-size: 12px;
  }
}

.listNews li {
  display: flex;
  align-items: center;
  border-top: 1px solid #E3E3E3;
  min-height: 100px;
  padding: 25px 180px 25px 20px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat;
  background-position: right 38px top 50%;
}
.listNews li:last-child {
  border-bottom: 1px solid #E3E3E3;
}
.listNews li .ttl,
.listNews li .wp-block-rss__item-publish-date {
  width: 100px;
  font-size: 14px;
  margin-right: 25px;
}
.listNews li .link,
.listNews li .wp-block-rss__item-title {
  display: block;
  width: calc(100% - 100px);
  padding-right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 1.5;
}
.listNews li .link:hover,
.listNews li .wp-block-rss__item-title:hover {
  text-decoration: none;
}
.listNews li .label,
.listNews li .wp-block-rss__item-author {
  display: none;
  font-size: 12px;
  line-height: 1.33;
  white-space: nowrap;
  border: 1px solid #707070;
  padding: 2px 16px 1px;
  margin-right: 15px;
}
.listNews li a.label {
  color: #333;
}
.listNews li a.label:hover {
  text-decoration: none;
}
.listNews.listFile li {
  background: none;
  padding: 25px 0;
  transition: 0.3s;
}
.listNews.listFile li:hover .link,
.listNews.listFile li:hover .link span {
  text-decoration: underline;
}
.listNews.listFile li .label {
  display: block;
}
.listNews + .btnDetail {
  margin-top: 40px;
}
.listNews02 li .label {
  display: block;
}
.listNews03 li {
  min-height: 0;
  padding-right: 80px;
}
.listNews03 a {
  display: block;
  width: calc(100% - 100px);
  padding-right: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 1.5;
}
.listNews03 a:hover {
  text-decoration: none;
}
.listNews04 {
  margin-bottom: 30px;
}
.listNews04 li {
  min-height: 0;
  padding-right: 80px;
  font-size: 14px;
}
.listNews04 a {
  display: block;
  width: calc(100% - 185px);
  padding-right: 15px;
  margin-left: 25px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 1.5;
}
.listNews04 a:hover {
  text-decoration: none;
}
.listNews.wp-block-rss .wp-block-rss__item-publish-date {
  order: 1;
  width: 150px;
  color: #333;
  white-space: nowrap;
}
.listNews.wp-block-rss .wp-block-rss__item-author {
  order: 2;
  display: block;
  color: #333;
}
.listNews.wp-block-rss .wp-block-rss__item-title {
  order: 3;
}
.listNews.wp-block-rss .wp-block-rss__item-title a {
  color: #333;
}
.listNews.wp-block-rss .wp-block-rss__item-title a:hover {
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  .listNews li {
    transition: 0.3s;
  }
  .listNews li:hover {
    background-position: right 20px top 50%;
  }
}
@media only screen and (max-width: 1024px) {
  .listNews li {
    padding-right: 40px;
    background-position-x: calc(100% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  .listNews li {
    height: auto;
    flex-direction: column;
    padding: 20px 35px 20px 0;
    background-position: right 13px top 50%;
  }
  .listNews li .ttl,
.listNews li .wp-block-rss__item-publish-date {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .listNews li .link,
.listNews li .wp-block-rss__item-title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding-right: 0;
  }
  .listNews li .label,
.listNews li .wp-block-rss__item-author {
    margin-right: auto;
    margin-bottom: 10px;
  }
  .listNews.listFile li {
    padding: 20px 0;
  }
  .listNews.wp-block-rss .wp-block-rss__item-publish-date {
    width: 100%;
  }
}

.companyList > .wp-block-group__inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.companyBox, .companyBox01 {
  width: calc(50% - 20px);
  margin-bottom: 30px;
  padding-bottom: 20px;
  text-decoration: none;
}
.companyBox .image, .companyBox01 .image {
  overflow: hidden;
  height: 26vw;
  max-height: 270px;
  margin-bottom: 25px;
  text-align: center;
}
.companyBox .image img, .companyBox01 .image img {
  transition: 0.3s;
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.companyBox .boxText, .companyBox01 .boxText {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 60px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat top 50% right 30px;
  background-size: 21px 20px;
  transition: 0.3s;
}
.companyBox .boxText .ttl, .companyBox01 .boxText .ttl {
  margin-bottom: 17px;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.5;
  color: #333;
}
.companyBox .boxText .ttl a, .companyBox01 .boxText .ttl a {
  color: inherit;
}
.companyBox .boxText .ttl a:hover, .companyBox01 .boxText .ttl a:hover {
  text-decoration: none;
}
.companyBox .boxText .txt, .companyBox01 .boxText .txt {
  font-size: 16px;
  line-height: 1.35;
  color: #000;
}
.companyBox.bl-hover .image img, .bl-hover.companyBox01 .image img {
  transform: scale(1.04);
}
.companyBox.bl-hover .boxText, .bl-hover.companyBox01 .boxText {
  background-position: top 50% right 15px;
}
@media only screen and (max-width: 1024px) {
  .companyBox, .companyBox01 {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .companyBox .image, .companyBox01 .image {
    margin-bottom: 15px;
  }
  .companyBox .boxText, .companyBox01 .boxText {
    padding-right: 30px;
    background-position-x: calc(100% - 10px);
    background-size: 15px 14px;
  }
  .companyBox .boxText .ttl, .companyBox01 .boxText .ttl {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .companyBox .boxText .txt, .companyBox01 .boxText .txt {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .companyBox .image img, .companyBox01 .image img {
    width: 100%;
  }
}
@media only screen and (max-width: 650px) {
  .companyBox, .companyBox01 {
    width: 100%;
  }
  .companyBox .image, .companyBox01 .image {
    height: auto;
    max-height: inherit;
  }
  .companyBox .boxText, .companyBox01 .boxText {
    padding-right: 20px;
    background-position-x: 100%;
  }
}

.irList {
  margin-bottom: 30px;
}
.irList.bottomPos {
  margin-top: 150px;
  margin-bottom: -80px;
}
@media only screen and (max-width: 768px) {
  .irList.bottomPos {
    margin-top: 70px;
    margin-bottom: 0;
  }
}
.irList > .wp-block-group__inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -13px;
}

.companyBox01 {
  width: calc(100% / 3);
  margin-bottom: 80px;
  padding: 0px 13px;
}
.companyBox01 .image {
  height: auto;
  margin-bottom: 18px;
}
.companyBox01 .boxText {
  padding-left: 10px;
  background-position: top 50% right 20px;
  background-size: 18px auto;
}
.companyBox01 .boxText .ttl {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1.75;
}
.companyBox01.bl-hover .image img {
  transform: scale(1.1);
}
@media only screen and (max-width: 1024px) {
  .companyBox01 {
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .companyBox01 {
    width: 100%;
  }
  .companyBox01 .boxText .ttl {
    font-size: 18px;
  }
}

.pageNav {
  margin-top: 100px;
}
.pageNav > .wp-block-group__inner-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -4px;
}

.btnBox {
  width: calc(25% - 8px);
  margin: 0px 4px;
  min-height: 56px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  position: relative;
  transition: 0.3s;
}
.btnBox a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #333;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 35px 10px 55px;
  transition: 0.3s;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat right 20px top 50%;
}
.btnBox a:hover {
  text-decoration: none;
  color: #FFF;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg) no-repeat right 15px top 50%;
}
.btnBox.icon01 {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_stock.svg) no-repeat 20px 50%;
}
.btnBox.icon02 {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_price.svg) no-repeat 20px 50%;
}
.btnBox.icon03 {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_calendar.svg) no-repeat 20px 50%;
}
.btnBox.icon04 {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_disclaimer.svg) no-repeat 20px 50%;
  background-size: 14px auto;
}
.btnBox:hover.icon01 {
  background: #00263E url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_stock_white.svg) no-repeat 20px 50%;
}
.btnBox:hover.icon02 {
  background: #00263E url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_price_white.svg) no-repeat 20px 50%;
}
.btnBox:hover.icon03 {
  background: #00263E url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_calendar_white.svg) no-repeat 20px 50%;
}
.btnBox:hover.icon04 {
  background: #00263E url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_disclaimer_white.svg) no-repeat 20px 50%;
  background-size: 14px auto;
}
@media only screen and (max-width: 768px) {
  .btnBox {
    width: calc(50% - 8px);
    margin-bottom: 10px;
  }
  .btnBox a {
    font-size: 13px;
    padding: 13px 15px 12px 50px;
  }
}
@media only screen and (max-width: 480px) {
  .btnBox {
    width: 100%;
    margin-bottom: 10px;
  }
}

.discList {
  list-style-type: disc;
  padding-left: 1.2em;
}

.decimalList {
  list-style-type: decimal;
  padding-left: 1.2em;
}

.yearBox {
  margin-bottom: 80px;
}
.yearBox .ttlYear {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 10px;
}
.yearBox .newList li {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 18px 10px 16px;
  line-height: 1.57;
  border-top: 1px solid #E3E3E3;
}
.yearBox .newList li:last-child {
  border-bottom: 1px solid #E3E3E3;
}
.yearBox .newList .date {
  margin-right: 20px;
  min-width: 107px;
}
.yearBox .newList .linkPdf a {
  color: #1872B4;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_pdf.svg) no-repeat 100% 50%;
  padding-right: 28px;
}
.yearBox .newList .newDetail {
  font-size: 16px;
  line-height: 1.6;
}
.yearBox .newList .newDetail span {
  display: block;
}
@media only screen and (max-width: 768px) {
  .yearBox {
    margin-bottom: 50px;
  }
  .yearBox .ttlYear {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .yearBox .newList li {
    font-size: 13px;
    padding: 15px 10px 13px;
  }
  .yearBox .newList .date {
    margin-right: 15px;
    min-width: 100px;
  }
  .yearBox .newList .newDetail {
    font-size: 15px;
  }
  .yearBox .newList .linkPdf {
    background-size: 15px auto;
    padding-right: 22px;
  }
}
@media only screen and (max-width: 480px) {
  .yearBox .newList li {
    flex-wrap: wrap;
  }
  .yearBox .newList .date {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.jsBgIE > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imgItem.wp-block-image {
  margin-bottom: 35px;
}
@media only screen and (max-width: 768px) {
  .imgItem.wp-block-image {
    margin-bottom: 20px;
  }
}

.profileTable {
  margin-bottom: 94px;
}
.profileTable table {
  font-size: 16px;
  line-height: 2.35;
}
.profileTable table th {
  width: 189px;
  padding: 28px 10px 29px;
  border: 1px solid #ccc;
  background-color: #fbfbfb;
  font-weight: bold;
  text-align: center;
}
.profileTable table td {
  padding: 28px 39px 29px;
  border: 1px solid #ccc;
}
.profileTable table td a {
  color: #1872B4;
  text-decoration: underline;
}
.profileTable table td a:hover {
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .profileTable {
    margin-bottom: 50px;
  }
  .profileTable table {
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.7;
  }
  .profileTable table th {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: none;
    text-align: left;
  }
  .profileTable table td {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: none;
  }
}

.profileImage {
  margin-bottom: 48px !important;
}
@media only screen and (max-width: 768px) {
  .profileImage {
    margin-bottom: 50px !important;
  }
}

.profileTable01 {
  margin-bottom: 94px;
}
.profileTable01 table {
  border: 1px solid #ccc;
  font-size: 15px;
  line-height: 1.7;
}
.profileTable01 table th {
  width: 189px;
  padding: 5px 28px 5px;
  border-right: 1px solid #ccc;
  background-color: #fbfbfb;
  font-weight: bold;
  text-align: right;
  vertical-align: top;
}
.profileTable01 table th span {
  padding-left: 5px;
}
.profileTable01 table td {
  padding: 5px 28px 5px;
}
.profileTable01 table .firstRow th,
.profileTable01 table .firstRow td {
  padding: 33px 28px 5px;
}
.profileTable01 table .lastRow th,
.profileTable01 table .lastRow td {
  padding: 5px 28px 37px;
}
@media only screen and (max-width: 768px) {
  .profileTable01 {
    margin-bottom: 50px;
  }
  .profileTable01 table {
    font-size: 14px;
    line-height: 1.7;
  }
  .profileTable01 table th {
    display: block;
    width: 100%;
    padding: 10px;
    border-right: none;
    text-align: left;
  }
  .profileTable01 table th span {
    padding-left: 5px;
  }
  .profileTable01 table td {
    display: block;
    width: 100%;
    padding: 10px;
  }
  .profileTable01 table .firstRow th,
.profileTable01 table .firstRow td {
    padding: 10px;
  }
  .profileTable01 table .lastRow th,
.profileTable01 table .lastRow td {
    padding: 10px;
  }
}

.profileText01 {
  padding-bottom: 24px;
  font-size: 15px;
  line-height: 1.7;
}
.profileText01 a {
  color: #1872B4;
  text-decoration: underline;
}
.profileText01 a:hover {
  text-decoration: none;
}
.profileText01 a span {
  padding-left: 12px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_blue.svg) no-repeat 0 50%;
  background-size: 7px auto;
}
@media only screen and (max-width: 768px) {
  .profileText01 {
    padding-bottom: 15px;
    font-size: 14px;
  }
}

.profileMap {
  position: relative;
  height: 0;
  margin-bottom: 26px;
  padding-bottom: 50%;
}
.profileMap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .profileMap {
    margin-bottom: 15px;
    padding-bottom: 60%;
  }
}

.kurataImg {
  text-align: right;
  margin-top: -57px;
  margin-bottom: 62px;
}
.kurataImg .wp-block-image {
  overflow: hidden;
  margin-bottom: 8px;
}
.kurataImg img {
  max-width: 201px;
}
.kurataImg .txtSign {
  padding-top: 0;
  font-size: 15px;
  margin-bottom: 12px;
}
.kurataImg figure {
  line-height: 0;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 768px) {
  .kurataImg {
    margin-top: 0;
  }
}

#pageHome #contents {
  padding: 0 0 80px;
}

.sectionNews {
  width: 100%;
  margin-bottom: 100px;
}
.sectionNews02 .tab02 {
  margin-bottom: 25px;
}
.sectionNews02 .headTab {
  justify-content: center;
}
.sectionNews02 .headline1 {
  display: none;
}
@media only screen and (max-width: 768px) {
  .sectionNews {
    margin-bottom: 100px;
  }
}

.sectionInfos {
  margin-bottom: 110px;
}
.sectionInfos .innerMax {
  display: flex;
  justify-content: space-between;
}
.sectionInfos .innerMax .blockLeft {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/home/img_01.jpg) no-repeat 50% 50%;
  background-size: 100% auto;
}
.sectionInfos .innerMax .blockRight {
  background: url(/wp-content/themes/twentytwenty-child/assets/img/home/img_02.jpg) no-repeat 50% 50%;
  background-size: 100% auto;
}
.sectionInfos .innerMax .blockInfos {
  transition: all 0.3s;
  cursor: pointer;
}
@media only screen and (min-width: 769px) {
  .sectionInfos .innerMax .blockInfos.wp-block-column {
    flex-basis: calc(50% - 10px) !important;
  }
  .sectionInfos .innerMax .blockInfos.blockRight {
    margin-left: 20px !important;
  }
  .sectionInfos .innerMax .blockInfos:hover {
    background-size: calc(100% + 40px) auto;
  }
  .sectionInfos .innerMax .blockInfos:hover .box .ttl:after {
    bottom: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .sectionInfos {
    margin-bottom: 60px;
    margin-top: 60px;
  }
  .sectionInfos .innerMax .blockLeft {
    margin-bottom: 15px;
    position: relative;
  }
  .sectionInfos .innerMax .blockLeft::after {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 30px;
    right: 20px;
    background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg) no-repeat 50% 50%;
    background-size: 20px 19px;
    content: "";
  }
}

.blockInfos {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: calc(50% - 10px);
  height: 0;
  padding-bottom: 31.15%;
  transition: opacity 0.3s;
  overflow: hidden;
}
.blockInfos .box {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 440px;
  width: 100%;
}
.blockInfos .box.style {
  top: calc(50% + 75px);
}
.blockInfos .box .ttl {
  position: relative;
  padding-bottom: 30px;
  font-size: 32px;
  color: #FFF;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  transition: all 0.3s;
}
.blockInfos .box .ttl::after {
  position: absolute;
  width: 80px;
  height: 3px;
  bottom: 0;
  left: 50%;
  background-color: #FFF;
  transform: translateX(-50%);
  content: "";
}
.blockInfos .box .listInfos {
  margin-top: 30px;
}
@media only screen and (max-width: 1300px) {
  .blockInfos .box.style {
    top: 58%;
  }
}
@media only screen and (max-width: 1024px) {
  .blockInfos .box .ttl {
    font-size: 25px;
    padding-bottom: 13px;
  }
}
@media only screen and (max-width: 768px) {
  .blockInfos {
    padding-bottom: 80.6%;
    width: 100%;
  }
}

.listInfos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.listInfos li {
  display: flex;
  width: calc(50% - 5px);
  margin-bottom: 10px;
  transition: all 0.3s;
  opacity: 1;
}
.listInfos li a {
  font-size: 16px;
  line-height: 1.3;
  color: #FFF;
  display: block;
  width: 100%;
  position: relative;
  padding: 15px 35px;
  font-weight: bold;
  border-radius: 5px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right_white.svg) no-repeat rgba(255, 255, 255, 0.3);
  background-position-x: calc(100% - 20px);
  background-position-y: 50%;
  text-align: center;
  text-decoration: none;
}
@media only screen and (min-width: 769px) {
  .listInfos li a {
    transition: 0.3s;
  }
  .listInfos li a:hover {
    background-color: #00263E;
  }
}
@media only screen and (max-width: 1024px) {
  .listInfos {
    max-width: 320px;
    margin: 0 auto;
  }
  .listInfos li a {
    font-size: 12px;
  }
}
@media only screen and (max-width: 768px) {
  .listInfos li a {
    padding: 15px 25px;
    color: #FFF;
    background-position-x: calc(100% - 10px);
    background-color: #00263E;
    font-size: 14px;
  }
}
@media only screen and (max-width: 374px) {
  .listInfos {
    max-width: 260px;
  }
  .listInfos li a {
    padding: 10px 20px;
    color: #FFF;
    background-position-x: calc(100% - 8px);
    background-color: #00263E;
    background-size: 8px 8px;
    font-size: 10px;
  }
}

.blockInfos {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: calc(50% - 10px);
  height: 0;
  padding-bottom: 31.15%;
  transition: opacity 0.3s;
  overflow: hidden;
}
.blockInfos .box {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 440px;
  width: 100%;
}
.blockInfos .box.style {
  top: calc(50% + 75px);
}
.blockInfos .box .ttl {
  position: relative;
  padding-bottom: 30px;
  font-size: 32px;
  color: #FFF;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
}
.blockInfos .box .ttl a {
  color: #FFF;
  text-decoration: none;
}
.blockInfos .box .ttl::after {
  position: absolute;
  width: 80px;
  height: 3px;
  bottom: 0;
  left: 50%;
  background-color: #FFF;
  transform: translateX(-50%);
  content: "";
  transition: 0.3s;
}
.blockInfos .box .listInfos {
  margin-top: 30px;
}
@media only screen and (max-width: 1300px) {
  .blockInfos .box.style {
    top: 58%;
  }
}
@media only screen and (max-width: 1024px) {
  .blockInfos .box .ttl {
    font-size: 25px;
    padding-bottom: 13px;
  }
}
@media only screen and (max-width: 768px) {
  .blockInfos {
    padding-bottom: 0;
    width: 100%;
    height: 72vw;
    background-size: cover !important;
  }
  .blockInfos.wp-block-column {
    flex-basis: 100% !important;
  }
  .blockInfos.wp-block-column:nth-child(2n) {
    margin-left: 0;
  }
}

.sectionProject {
  background-color: #00263E;
  margin-bottom: 110px;
  padding: 130px 0 85px;
}
.sectionProject .ttl {
  margin-bottom: 55px;
  color: #FFF;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.3;
}
.sectionProject .boxText {
  display: flex;
  margin-bottom: 40px;
}
.sectionProject .boxText .boxLeft {
  width: 50%;
  padding: 15px 40px 50px 0;
  border-right: 1px solid #FFF;
  box-sizing: border-box;
}
.sectionProject .boxText .boxLeft .wp-block-buttons {
  width: 100%;
  text-align: right;
}
.sectionProject .boxText .boxRight {
  width: 50%;
  padding: 15px 0 50px 40px;
}
.sectionProject .boxText .boxRight .wp-block-buttons {
  text-align: left;
}
.sectionProject .boxText .txt {
  margin-bottom: 30px;
  color: #FFF;
  font-size: 17px;
  font-weight: bold;
  line-height: 2;
}
@media only screen and (min-width: 769px) {
  .sectionProject .wp-block-column {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}
@media only screen and (max-width: 768px) {
  .sectionProject {
    padding: 67px 0 50px 0;
    margin: 0 -20px 30px -20px;
  }
  .sectionProject .ttl {
    padding: 0 10px;
    font-size: 20px;
    line-height: 1.55;
    margin-bottom: 25px;
  }
  .sectionProject .boxText {
    display: block;
    margin-bottom: 0;
  }
  .sectionProject .boxText .txt {
    font-size: 14px;
  }
  .sectionProject .boxText .boxLeft {
    width: 100%;
    padding: 0 10px;
    border-right: 0;
  }
  .sectionProject .boxText .boxLeft .wp-block-buttons {
    text-align: center;
  }
  .sectionProject .boxText .boxRight {
    display: none;
  }
  .sectionProject .boxText .boxRight .wp-block-buttons {
    text-align: center;
  }
}

.companySection {
  padding: 90px 0 100px;
  background-color: #F1F1F1;
}
.companySection .headline1 {
  margin-bottom: 25px;
  padding-left: 45px;
}
.companySection .wp-block-columns {
  margin-bottom: 0;
}
.companySection02 {
  background-color: #FFF;
  padding: 40px 0 0;
}
.companySection02 .companyBlock {
  padding: 40px 80px 40px 0;
  border-bottom: 1px solid #E3E3E3;
  background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right02.svg") no-repeat right 15px top 50%;
  background-size: 18px auto;
  transition: 0.3s;
}
.companySection02 .companyBlock:hover {
  background-position: right 0 top 50%;
}
.companySection02 .companyBlock:last-child {
  border-bottom: none;
}
.companySection02 .companyBlock .image {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #808080;
  border-radius: 10px;
  overflow: hidden;
  min-height: 185px;
}
.companySection02 .companyBlock .image a {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}
.companySection02 .companyBlock .image figcaption {
  margin-bottom: 0;
}
.companySection02 .companyBlock .image .wp-block-image {
  margin-bottom: 0;
}
.companySection02 .companyBlock .listSocial {
  width: 100%;
}
.companySection02 .companyBlock .listSocial > * {
  border-top: none;
}
.companySection02 .companyBlock .boxText {
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.companySection02.companySection03 {
  background-color: transparent;
  padding: 40px 25px 0;
}
.companySection02.companySection03 .wp-block-image img {
  margin-bottom: 5px;
}
.companySection02.companySection03 .companyBlock {
  border-bottom: none;
  background-color: #FFF;
  margin-bottom: 25px;
  background-position: right 35px top 50%;
  padding: 35px 80px 35px 35px;
  justify-content: space-between;
}
.companySection02.companySection03 .companyBlock:last-child {
  margin-bottom: 0;
}
.companySection02.companySection03 .companyBlock:hover {
  background-position: right 20px top 50%;
}
.companySection02.companySection03 .companyBlock .image {
  border: none;
  margin-top: 12px;
}
.companySection02.companySection03 .companyBlock .image figcaption {
  font-size: 14px;
  font-weight: bold;
}
.companySection02.companySection03 .companyBlock .listSocial p {
  width: auto;
  min-width: 42%;
}
@media only screen and (max-width: 1200px) {
  .companySection02.companySection03 .companyBlock .listSocial p {
    width: 100%;
  }
}
.companySection02.companySection03 .companyBlock .listSocial > * {
  padding-top: 28px;
  justify-content: initial;
}
@media only screen and (max-width: 768px) {
  .companySection {
    margin: 0 -20px;
    padding: 80px 0 90px;
  }
  .companySection .headline1 {
    margin-bottom: 40px;
    padding-left: 0;
  }
  .companySection .wp-block-column {
    flex-basis: 100% !important;
    margin-left: 0 !important;
  }
  .companySection02 {
    padding: 20px 0 0;
  }
  .companySection02 .companyBlock {
    padding-right: 0;
    background: none;
  }
  .companySection02 .companyBlock .image {
    margin-bottom: 15px;
  }
  .companySection02 .companyBlock .boxText {
    padding-right: 60px;
    background: url("/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right02.svg") no-repeat right 15px top 50%;
  }
  .companySection02.companySection03 {
    padding: 20px 0 0;
  }
  .companySection02.companySection03 .companyBlock {
    padding: 35px 20px;
  }
  .companySection02.companySection03 .companyBlock .boxText {
    padding-right: 40px;
    background-position: right 0px top 50%;
  }
  .companySection02.companySection03 .companyBlock .image {
    margin-top: 0;
    padding: 15px;
    min-height: 0;
  }
}

.companyGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.companyBlock {
  position: relative;
  width: calc(50% - 15px);
  margin-bottom: 30px;
  padding: 65px 35px 40px;
  transition: all 0.3s;
  cursor: pointer;
}
.companyBlock:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFF;
  transition: all 0.3s;
}
.companyBlock:hover:after {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}
@media only screen and (min-width: 769px) {
  .companyBlock:hover .titleLink {
    background-position: right 0 top 5px;
  }
}
.companyBlock .blockInner {
  position: relative;
  z-index: 1;
}
.companyBlock .titleLink {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  margin-bottom: 48px;
  padding-right: 35px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_arrow_right.svg) no-repeat;
  background-position: right 10px top 5px;
  background-size: 18px 18px;
  text-decoration: none;
  margin-right: -10px;
  transition: 0.3s;
}
.companyBlock .titleLink .imageTitle {
  margin-bottom: 15px;
}
.companyBlock .titleLink .subTitle {
  font-size: 14px;
  line-height: 1.3;
  color: #333;
}
.companyBlock .boxText {
  margin-bottom: 40px;
  color: #333;
}
.companyBlock .boxText .ttl {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}
.companyBlock .boxText .txt {
  font-size: 16px;
  line-height: 1.6;
}
.companyBlock .listSocial {
  width: 100%;
}
.companyBlock .listSocial > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #D5D5D5;
}
.companyBlock .listSocial.fullwidth {
  width: 100%;
}
.companyBlock .listSocial.fullwidth > * {
  display: block;
}
.companyBlock .listSocial.fullwidth > * p {
  width: 100%;
}
.companyBlock .listSocial p {
  width: calc(50% - 5px);
}
.companyBlock .listSocial p.facebook {
  padding: 7px 5px 4px 25px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_facebook.svg) no-repeat;
  background-position: 0 6px;
}
.companyBlock .listSocial p.instagram {
  padding: 7px 5px 4px 25px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_instagram.svg) no-repeat;
  background-position: 0 6px;
}
.companyBlock .listSocial p.youtube {
  padding: 7px 5px 4px 29px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/icon_youtube.svg) no-repeat;
  background-position: 0 6px;
}
.companyBlock .listSocial p.auctionNote {
  padding: 7px 5px 4px 25px;
  background: url(/wp-content/themes/twentytwenty-child/assets/img/common/note_logo.svg) no-repeat;
  background-position: 0 6px;
}
.companyBlock .listSocial p a {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
  text-decoration: none;
  color: #606060;
}
.companyBlock .listSocial p a:hover {
  text-decoration: underline;
}
.companyBlock.fullwidth {
  width: 100%;
}
.companyBlock.fullwidth:after {
  display: none;
}
@media only screen and (min-width: 769px) {
  .companyBlock .blockInner {
    height: 100%;
  }
  .companyBlock .blockInner > * {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
}
@media only screen and (max-width: 1200px) {
  .companyBlock {
    padding: 40px 25px 30px 25px;
    width: calc(50% - 10px);
  }
  .companyBlock .titleLink {
    margin-bottom: 38px;
    padding-right: 35px;
    background-position-x: calc(100% - 6px);
    background-position-y: 50%;
    background-size: 23px 20px;
    margin-right: 0;
  }
  .companyBlock .titleLink .imageTitle {
    margin-bottom: 10px;
  }
  .companyBlock .titleLink .subTitle {
    font-size: 16px;
    font-weight: bold;
  }
  .companyBlock .boxText {
    margin-bottom: 30px;
  }
  .companyBlock .boxText .ttl {
    font-size: 14px;
  }
  .companyBlock .boxText .txt {
    font-size: 14px;
    line-height: 1.58;
  }
  .companyBlock .listSocial {
    padding-top: 0;
    border-top: 0;
  }
  .companyBlock .listSocial p {
    width: 100%;
  }
}
@media only screen and (max-width: 650px) {
  .companyBlock {
    width: 100%;
    margin-bottom: 20px;
  }
}

.newsArticle {
  margin-bottom: 70px;
}
.newsArticle .timeBox {
  margin-bottom: 30px;
}
.newsArticle .timeBox .date {
  display: inline-block;
  margin-right: 13px;
  font-size: 16px;
  line-height: 1.3125;
}
.newsArticle .timeBox .cat {
  display: inline-block;
  font-size: 12px;
  border: 1px solid #333;
  line-height: 1.33;
  padding: 2px 5px;
  min-width: 63px;
  text-align: center;
  vertical-align: text-bottom;
}
.newsArticle .noteList {
  font-size: 16px;
  line-height: 2;
}
.newsArticle .txtN {
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  .newsArticle {
    margin-bottom: 40px;
  }
}

.ttlN {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.75;
  margin-bottom: 25px;
}
@media only screen and (max-width: 768px) {
  .ttlN {
    margin-bottom: 15px;
    font-size: 17px;
  }
}

.articleSection .date {
  font-size: 13px;
  line-height: 1.31;
  margin-bottom: 8px;
}
.articleSection .txtIntro {
  font-size: 13px;
  line-height: 1.5385;
}
.articleSection li {
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.articleSection li:last-child {
  margin-bottom: 0;
}
.articleSection a {
  color: #333;
}

.categorySection .categoryList {
  font-size: 14px;
  line-height: 1.786;
}
.categorySection .categoryList a {
  color: #333;
}

.yearSection .yearList {
  font-size: 14px;
  line-height: 1.786;
}
.yearSection .yearList a {
  color: #333;
}

#side .section {
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  #side .section {
    margin-bottom: 40px;
  }
}

.newsArticle p {
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 2;
}
.newsArticle p.mBottom {
  margin-bottom: 90px;
}
@media only screen and (max-width: 768px) {
  .newsArticle p {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .newsArticle p.mBottom {
    margin-bottom: 45px;
  }
}

#pageIr #contents {
  padding-top: 120px;
}
@media only screen and (max-width: 768px) {
  #pageIr #contents {
    padding-top: 70px;
  }
}
#pageIr .pdfemb-viewer {
  margin-bottom: 30px;
}
#pageIr .irList.mTop {
  margin-top: 150px;
}

.contactBox {
  margin-bottom: 96px;
}
.contactBox:last-child {
  margin-bottom: 0;
}
.contactBox .tel {
  font-size: 40px;
  line-height: 1.325;
  color: #333;
  text-decoration: none;
  pointer-events: none;
}
.contactBox .btnLink {
  margin-top: 12px;
}
.contactBox .txt {
  font-size: 14px;
  line-height: 1.357;
}
@media only screen and (max-width: 768px) {
  .contactBox .txt {
    font-size: 12px;
  }
  .contactBox .tel {
    font-size: 30px;
  }
}

.labelBox {
  display: flex;
  font-size: 15px;
  color: #000;
  line-height: 1.33;
  font-weight: bold;
  margin-bottom: 8px;
}
.labelBox label {
  margin-right: 0;
}
.labelBox span {
  display: block;
  color: #FF2E2E;
  margin-left: 5px;
}

.formGroup {
  margin-bottom: 35px;
}
.formGroup .inputBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.formGroup.errorWrap input,
.formGroup.errorWrap select,
.formGroup.errorWrap textarea {
  border-color: #FF2E2E;
}
.formGroup.errorWrap .checkbox .label {
  border-color: #FF2E2E;
}

.contactForm {
  background-color: #F1F1F1;
  padding: 35px 40px;
}
.contactForm .error {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  color: #FF2E2E;
  font-weight: bold;
}
.contactForm .agreeWrap {
  margin-bottom: 15px;
}
.contactForm .link {
  display: block;
  font-size: 14px;
  line-height: 1.357;
  color: #1872B4;
  text-decoration: underline;
  margin-top: 20px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.contactForm .txt02 {
  font-size: 16px;
  line-height: 1.3125;
  padding: 0 10px;
}
.contactForm .mwform-checkbox-field {
  display: inline-block;
  position: relative;
  font-size: 15px;
  line-height: 1.33;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-right: 60px;
}
.contactForm .mwform-checkbox-field input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contactForm .mwform-checkbox-field input[type=checkbox]:checked ~ .mwform-checkbox-field-text:after {
  display: block;
}
.contactForm .mwform-checkbox-field:last-child {
  margin-right: 0;
}
.contactForm .mwform-checkbox-field.agree {
  font-weight: bold;
}
.contactForm .mwform-checkbox-field.agree.errorWrap .label {
  border-color: #FF2E2E;
}
.contactForm .mwform-checkbox-field ~ .checkbox {
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .contactForm .mwform-checkbox-field ~ .checkbox {
    margin-top: 10px;
  }
}
.contactForm .mwform-checkbox-field .mwform-checkbox-field-text {
  position: relative;
  padding-left: 30px;
}
.contactForm .mwform-checkbox-field .mwform-checkbox-field-text:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  content: "";
  background-color: #FFF;
  border: 1px solid #B4B4B4;
  border-radius: 5px;
}
.contactForm .mwform-checkbox-field .mwform-checkbox-field-text:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.contactForm .mwform-checkbox-field label {
  cursor: pointer;
}
.contactForm .mw_wp_form_confirm .txt,
.contactForm .mw_wp_form_confirm .agreeWrap,
.contactForm .mw_wp_form_confirm .noteList,
.contactForm .mw_wp_form_confirm .phone {
  display: none;
}
.contactForm .mw_wp_form_confirm .inputBox {
  font-size: 16px;
  line-height: 1.3125;
  padding: 0 10px;
}
.contactForm .mw_wp_form_confirm .inputBox input[name="__children[byPhone][]"] + br {
  display: none;
}

.agreeWrap .mwform-checkbox-field-text {
  font-weight: bold;
}

.mw_wp_form_confirm .labelBox span {
  display: none;
}

@media only screen and (max-width: 768px) {
  #phone {
    margin-top: 10px;
  }

  .formGroup {
    margin-bottom: 20px;
  }

  .contactForm {
    padding: 20px;
  }

  .contactBox {
    margin-bottom: 50px;
  }

  .txt02 {
    font-size: 14px;
  }
}
.sitemapSection .headline9 {
  margin-bottom: 20px;
}
.sitemapSection .headline9.mTop {
  margin-top: 50px;
}
.sitemapSection a {
  display: block;
  font-size: 15px;
  line-height: 2;
  color: #333;
  text-decoration: none;
  transition: 0.3s;
}
.sitemapSection a:hover {
  transform: translateX(10px);
}
@media only screen and (min-width: 782px) {
  .sitemapSection .wp-block-columns {
    flex-wrap: wrap;
  }
  .sitemapSection .wp-block-columns .wp-block-column:last-child {
    flex-basis: 100%;
    margin-left: 0;
  }
}
@media only screen and (max-width: 781px) {
  .sitemapSection .wp-block-column {
    margin-bottom: 25px;
  }
  .sitemapSection .wp-block-column > .wp-block-columns:last-child() {
    margin-bottom: 0;
  }
  .sitemapSection .headline9 {
    margin-bottom: 15px;
  }
  .sitemapSection .headline9.mTop {
    margin-top: 0;
  }
  .sitemapSection.wp-block-columns {
    margin-bottom: 0;
  }
  .sitemapSection.wp-block-columns > .wp-block-column:last-child {
    flex-basis: 100% !important;
    margin-bottom: 0;
  }
}

.faqBlock {
  border-top: 1px solid #E3E3E3;
  margin-bottom: 100px;
}
.faqBlock:last-child {
  margin-bottom: 0;
}
.faqBlock .faqQuestion {
  position: relative;
  font-size: 16px;
  line-height: 1.3125;
  padding: 28px 100px 28px 0;
  border-bottom: 1px solid #E3E3E3;
  background: url("http://shinwa-wise.test.vm/html/assets/img/common/icon_arrow_down02.svg") no-repeat right 47px top 50%;
  cursor: pointer;
  transition: 0.3s;
}
.faqBlock .faqQuestion > * {
  display: flex;
}
.faqBlock .faqQuestion.active {
  background-image: url("http://shinwa-wise.test.vm/html/assets/img/common/icon_arrow_up.svg");
  border-bottom-color: transparent;
}
.faqBlock .faqQuestion:hover {
  opacity: 0.7;
}
.faqBlock .icon {
  font-weight: bold;
  width: 45px;
  margin-right: 5px;
}
.faqBlock .txt {
  width: calc(100% - 50px);
}
.faqBlock .faqAnswer {
  display: none;
  font-size: 15px;
  line-height: 2;
  padding-bottom: 50px;
  padding-right: 100px;
  border-bottom: 1px solid #E3E3E3;
}
.faqBlock .faqAnswer .flexBox > * {
  display: flex;
}
.faqBlock .faqItem {
  border-bottom: 1px solid #E3E3E3;
}
@media only screen and (max-width: 768px) {
  .faqBlock {
    margin-bottom: 50px;
  }
  .faqBlock .faqQuestion {
    padding: 14px 30px 14px 0;
    background-position: right 15px top 50%;
  }
  .faqBlock .faqAnswer {
    padding-bottom: 15px;
    padding-right: 30px;
  }
}