// -----------------------------------------------------------------------------
// Layout Settings
// -----------------------------------------------------------------------------
.spDisplay {
    display: none !important;
    @include media-down(sp) {
        display: block !important;
    }
}

.pcDisplay {
    display: block !important;
    @include media-down(sp) {
        display: none !important;
    }
}

#main {
    width: 100%;
}
#contents {
    display: block;
    padding: 85px 0 150px;

    @include media-down(sp) {
        padding: 45px 0 70px;
    }
}
.flexContents {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 20px !important;
    #main {
        width: calc(100% - 250px);
        float: left;

    }
    #side {
        width: 190px;
        float: right;
    }
    @include media-down(sp) {
        #main {
            width: 100%;
            float: none;

        }
        #side {
            width: 100%;
            float: none;
            margin-top: 50px;
        }
    }
}
.inner {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.innerMax {
    max-width: 1760px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.inner01 {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

body {
    padding-top: 110px;
    @include media-down(999) {
        padding-top: 74px;
    }
}
.iconPdf {
    display: inline-block;
    padding-right: 30px;
    background: image('common/icon_pdf.svg') no-repeat 100% 0;
}
