// -----------------------------------------------------------------------------
// Text style
// -----------------------------------------------------------------------------
.txtN {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 2;
    &.mBottom {
        margin-bottom: 90px;
    }
    @include media-down(sp) {
        margin-bottom: 20px;
        font-size: 14px;
        &.mBottom {
            margin-bottom: 45px;
        }
    }

}
.txtSign {
    margin-bottom: 35px;
    padding-top: 38px;
    font-size: 14px;
    line-height: 1.7;
    text-align: right;
    @include media-down(sp) {
        padding-top: 30px;
    }
}
.space01 {
    margin-bottom: 125px;
    @include media-down(sp) {
        margin-bottom: 65px;
    }
}
.space02 {
    margin-bottom: 45px;
    @include media-down(sp) {
        margin-bottom: 30px;
    }
}
.space03 {
    margin-bottom: 70px;
    @include media-down(sp) {
        margin-bottom: 40px;
    }
}
.space04 {
    margin-bottom: 150px;
    @include media-down(sp) {
        margin-bottom: 80px;
    }
}

.txtLink {
    a {
        color: $color19;
    }
}
