// -----------------------------------------------------------------------------
// This file contains styles that are specific to the Company page.
// -----------------------------------------------------------------------------
.profileTable {
    margin-bottom: 94px;

    table {
        font-size: 16px;
        line-height: 2.35;

        th {
            width: 189px;
            padding: 28px 10px 29px;
            border: 1px solid #ccc;
            background-color: #fbfbfb;
            font-weight: bold;
            text-align: center;
        }

        td {
            padding: 28px 39px 29px;
            border: 1px solid #ccc;

            a {
                color: #1872B4;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @include media-down(sp) {
        margin-bottom: 50px;

        table {
            border-bottom: 1px solid #ccc;
            font-size: 14px;
            line-height: 1.7;

            th {
                display: block;
                width: 100%;
                padding: 10px;
                border-bottom: none;
                text-align: left;
            }

            td {
                display: block;
                width: 100%;
                padding: 10px;
                border-bottom: none;
            }
        }
    }
}

.profileImage {
    margin-bottom: 48px !important;

    @include media-down(sp) {
        margin-bottom: 50px !important;
    }
}

.profileTable01 {
    margin-bottom: 94px;

    table {
        border: 1px solid #ccc;
        font-size: 15px;
        line-height: 1.7;

        th {
            width: 189px;
            padding: 5px 28px 5px;
            border-right: 1px solid #ccc;
            background-color: #fbfbfb;
            font-weight: bold;
            text-align: right;
            vertical-align: top;

            span {
                padding-left: 5px;
            }
        }

        td {
            padding: 5px 28px 5px;
        }

        .firstRow {

            th,
            td {
                padding: 33px 28px 5px;
            }
        }

        .lastRow {

            th,
            td {
                padding: 5px 28px 37px;
            }
        }
    }

    @include media-down(sp) {
        margin-bottom: 50px;

        table {
            font-size: 14px;
            line-height: 1.7;

            th {
                display: block;
                width: 100%;
                padding: 10px;
                border-right: none;
                text-align: left;

                span {
                    padding-left: 5px;
                }
            }

            td {
                display: block;
                width: 100%;
                padding: 10px;
            }

            .firstRow {

                th,
                td {
                    padding: 10px;
                }
            }

            .lastRow {

                th,
                td {
                    padding: 10px;
                }
            }
        }
    }
}

.profileText01 {
    padding-bottom: 24px;
    font-size: 15px;
    line-height: 1.7;

    a {
        color: #1872B4;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        span {
            padding-left: 12px;
            background: url(#{$base-url}img/common/icon_arrow_blue.svg) no-repeat 0 50%;
            background-size: 7px auto;
        }
    }

    @include media-down(sp) {
        padding-bottom: 15px;
        font-size: 14px;
    }
}

.profileMap {
    position: relative;
    height: 0;
    margin-bottom: 26px;
    padding-bottom: 50%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include media-down(sp) {
        margin-bottom: 15px;
        padding-bottom: 60%;
    }
}

.kurataImg {
    text-align: right;
    margin-top: -57px;
    margin-bottom: 62px;
    .wp-block-image {
        overflow: hidden;
        margin-bottom: 8px;
    }
    img {
        max-width: 201px;
    }
    .txtSign {
        padding-top: 0;
        font-size: 15px;
        margin-bottom: 12px;
    }
    figure {
        line-height: 0;
        margin-bottom: 0 !important;
    }
    @include media-down(sp) {
        margin-top: 0;
    }
}

