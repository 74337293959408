// -----------------------------------------------------------------------------
// This file contains styles that are specific to the sitemap page.
// -----------------------------------------------------------------------------

.sitemapSection {
    .headline9 {
        margin-bottom: 20px;
        &.mTop {
            margin-top: 50px;
        }
    }
    a {
        display: block;
        font-size: 15px;
        line-height: 2;
        color: $text-color;
        text-decoration: none;
        transition: $transition-duration;
        &:hover {
            transform: translateX(10px);
        }
    }
    @include media-up(782) {
        .wp-block-columns {
            flex-wrap: wrap;
            .wp-block-column {
                // flex-grow: 1;
                &:last-child {
                    flex-basis: 100%;
                    margin-left: 0;
                }
            }
        }
    }
    @include media-down(781) {
        .wp-block-column {
            margin-bottom: 25px;

            > .wp-block-columns:last-child() {
                margin-bottom: 0;
            }
        }
        .headline9 {
            margin-bottom: 15px;
            &.mTop {
                margin-top: 0;
            }
        }
        &.wp-block-columns {
            margin-bottom: 0;

            & > .wp-block-column:last-child {
                flex-basis: 100% !important;
                margin-bottom: 0;
            }
        }
    }
}
