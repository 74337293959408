// -----------------------------------------------------------------------------
// Headline style
// -----------------------------------------------------------------------------
.headline1 {
    font-size: 32px;
    font-weight: bold;
    color: $color-black;
    line-height: 1.3;

    @include media-down(sp) {
        font-size: 25px;
        line-height: 1.5;
    }
}

.headline2 {
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.4;

    @include media-down(sp) {
        margin-bottom: 20px;
        font-size: 22px;
    }
}

.headline3 {
    position: relative;
    margin-bottom: 50px;
    padding: 0 0 23px 2px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.4;

    &:after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -1px;
        width: 100px;
        height: 1px;
        background-color: #606060;
    }

    @include media-down(sp) {
        margin-bottom: 30px;
        padding: 0 0 10px;
        font-size: 22px;
    }
}

.headline4 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.4;
    padding-bottom: 25px;
    border-bottom: 1px solid $color01;

    @include media-down(sp) {
        margin-bottom: 20px;
        padding-bottom: 15px;
        font-size: 20px;
    }
}

.headline5 {
    font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    padding-bottom: 25px;
    border-bottom: 1px solid $color01;
    margin-bottom: 40px;
    &.noBorder {
        padding-bottom: 0;
        border-bottom: none;
    }
    @include media-down(sp) {
        font-size: 20px;
    }
}
.headline6 {
    font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3125;
    border-bottom: 1px solid $color01;
    padding-bottom: 8px;
    margin-bottom: 15px;
    &:after {
        position: absolute;
        width: 60px;
        height: 1px;
        content: '';
        background-color: $color08;
        bottom: -1px;
        left: 0;
    }
}
.headline7 {
    font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.325;
    margin-bottom: 95px;
    @include media-down(sp) {
        font-size: 25px;
        margin-bottom: 45px;
    }
}
.headline8 {
    font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.75;
    border-bottom: 1px solid $color01;
    padding-bottom: 11px;
    margin-bottom: 26px;
    &:after {
        position: absolute;
        width: 100px;
        height: 1px;
        content: '';
        background-color: $color08;
        bottom: -1px;
        left: 0;
    }
    &.noBorder {
        border-bottom: none;
        padding-bottom: 0;
        &:after {
            display: none;
        }
    }
    @include media-down(sp) {
        font-size: 17px;
    }
}
.headline9 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
    line-height: 1.5;
    @include media-down(sp) {
        font-size: 17px;
        margin-bottom: 15px;
    }
}
.headline4Wrap {
    // border-bottom: 1px solid $color01;
    margin-bottom: 0 !important;
    .headline4 {
        border-bottom: none;
    }
    .readMore {
        text-align: right;
        a {
            display: inline-block;
            font-size: 15px;
            line-height: 2.2;
            color: $text-color;
            text-decoration: none;
            transition: $transition-duration;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @include media-down(sp) {
        flex-wrap: nowrap !important;
    }
}
.headline10 {
    font-size: 49px;
    font-weight: bold;
    margin-bottom: 25px;
    line-height: 0.7755;
    color: $color20;
    text-align: center;
    span {
        display: block;
        font-size: 29px;
        line-height: 1.31;
        margin-top: 6px;
    }
    @include media-down(sp) {
        font-size: 35px;
        margin-bottom: 15px;
        span {
            font-size: 20px;
        }
    }
}
