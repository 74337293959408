// -----------------------------------------------------------------------------
// List style
// -----------------------------------------------------------------------------

.listNews {

    li {
        display: flex;
        align-items: center;
        border-top: 1px solid $color01;
        min-height: 100px;
        padding: 25px 180px 25px 20px;
        background: url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat;
        background-position: right 38px top 50%;

        &:last-child {
            border-bottom: 1px solid $color01;
        }
        .ttl,
        .wp-block-rss__item-publish-date {
            width: 100px;
            font-size: 14px;
            margin-right: 25px;
        }
        .link,
        .wp-block-rss__item-title {
            display: block;
            width: calc(100% - 100px);
            padding-right: 15px;
            font-size: 16px;
            font-weight: bold;
            color: $text-color;
            line-height: 1.5;
            &:hover {
                text-decoration: none;
            }
        }
        .label,
        .wp-block-rss__item-author {
            display: none;
            font-size: 12px;
            line-height: 1.33;
            white-space: nowrap;
            border: 1px solid $color09;
            padding: 2px 16px 1px;
            margin-right: 15px;
        }
        a.label {
            color: $text-color;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &.listFile {
        li {
            background: none;
            padding: 25px 0;
            transition: $transition-duration;
            &:hover {
                .link,
                .link span {
                    text-decoration: underline;
                }
            }
            .label {
                display: block;
            }
        }
    }
    &+.btnDetail {
        margin-top: 40px;
    }
    &02 {
        li {
            .label {
                display: block;
            }
        }
    }
    &03 {
        li {
            min-height: 0;
            padding-right: 80px;
        }
        a {
            display: block;
            width: calc(100% - 100px);
            padding-right: 15px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 1.5;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &04 {
        margin-bottom: 30px;
        li {
            min-height: 0;
            padding-right: 80px;
            font-size: 14px;
        }
        a {
            display: block;
            width: calc(100% - 185px);
            padding-right: 15px;
            margin-left: 25px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            line-height: 1.5;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &.wp-block-rss {
        .wp-block-rss__item-publish-date {
            order: 1;
            width: 150px;
            color: #333;
            white-space: nowrap;
        }
        .wp-block-rss__item-author {
            order: 2;
            display: block;
            color: #333;
        }
        .wp-block-rss__item-title {
            order: 3;
            a {
                color: #333;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    @include media-up(sp) {
        li {
            transition: $transition-duration;
            &:hover {
                background-position: right 20px top 50%;
            }
        }
    }
    @include media-down(1024) {
        li {
            padding-right: 40px;
            background-position-x: calc(100% - 20px);
        }
    }
    @include media-down(sp) {
        li {
            height: auto;
            flex-direction: column;
            padding: 20px 35px 20px 0;
            background-position: right 13px top 50%;
            .ttl,
            .wp-block-rss__item-publish-date {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                font-size: 14px;
            }
            .link,
            .wp-block-rss__item-title {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                padding-right: 0;
            }
            .label,
            .wp-block-rss__item-author {
                margin-right: auto;
                margin-bottom: 10px;
            }
        }
        &.listFile {
            li {
                padding: 20px 0;
            }
        }
        &.wp-block-rss {
            .wp-block-rss__item-publish-date {
                width: 100%;
            }
            .wp-block-rss__item-author {

            }
            .wp-block-rss__item-title {

                a {

                }
            }
        }
    }
}

.companyList > .wp-block-group__inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.companyBox {
    width: calc(50% - 20px);
    margin-bottom: 30px;
    padding-bottom: 20px;
    text-decoration: none;
    .image {
        overflow: hidden;
        height: 26vw;
        max-height: 270px;
        margin-bottom: 25px;
        // flex: 1 1 auto;
        text-align: center;
        img {
            transition: $transition-duration;
            vertical-align: middle;
            // width: 100%;
            width: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .boxText {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-right: 60px;
        background: url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat top 50% right 30px;
        background-size: 21px 20px;
        transition: $transition-duration;

        .ttl {
            margin-bottom: 17px;
            font-size: 23px;
            font-weight: bold;
            line-height: 1.5;
            color: $text-color;

            a {
                color: inherit;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .txt {
            font-size: 16px;
            line-height: 1.35;
            color: $color-black;
        }
    }
    &.bl-hover {
        .image img {
            transform: scale(1.04);
        }

        .boxText {
            background-position: top 50% right 15px;
        }
    }

    @include media-down(1024) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
        padding-bottom: 10px;
        .image {
            margin-bottom: 15px;
        }
        .boxText {
            padding-right: 30px;
            background-position-x: calc(100% - 10px);
            background-size: 15px 14px;
            .ttl {
                margin-bottom: 10px;
                font-size: 16px;
            }
            .txt {
                font-size: 12px;
            }
        }
    }
    @include media-down(sp) {
        .image img {
            width: 100%;
        }
    }
    @include media-down(650) {
        width: 100%;
        .image {
            height: auto;
            max-height: inherit;
        }
        .boxText {
            padding-right: 20px;
            background-position-x: 100%;
        }
    }
}
.irList {
    margin-bottom: 30px;

    &.bottomPos {
        margin-top: 150px;
        margin-bottom: -80px;
        @include media-down(sp) {
            margin-top: 70px;
            margin-bottom: 0;
        }
    }
    > .wp-block-group__inner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -13px;
    }
}

.companyBox01 {
    @extend .companyBox;
    width: calc(100% / 3);
    margin-bottom: 80px;
    padding: 0px 13px;
    .image {
        height: auto;
        margin-bottom: 18px;
    }
    .boxText {
        padding-left: 10px;
        background-position: top 50% right 20px;
        background-size: 18px auto;
        .ttl {
            margin-bottom: 0;
            font-size: 20px;
            line-height: 1.75;
        }
    }
    &.bl-hover {
        .image img {
            transform: scale(1.1);
        }
    }

   @include media-down(1024) {
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
    @include media-down(sp) {
        width: 100%;
        .boxText {
            .ttl {
                font-size: 18px;
            }
        }
    }
}

.pageNav {
    margin-top: 100px;
    > .wp-block-group__inner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -4px;
    }
}

.btnBox {
    width: calc(25% - 8px);
    margin: 0px 4px;
    min-height: 56px;
    border-radius: 5px;
    border: 1px solid $color13;
    position: relative;
    transition: $transition-duration;
    a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        color: $text-color;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 35px 10px 55px;
        transition: $transition-duration;
        background:  url(#{$base-url}img/common/icon_arrow_right.svg) no-repeat right 20px top 50%;
        &:hover {
            text-decoration: none;
            color: $color-white;
            background:  url(#{$base-url}img/common/icon_arrow_right_white.svg) no-repeat right 15px top 50%;
        }

    }
    &.icon01 {
        background: url(#{$base-url}img/common/icon_stock.svg) no-repeat 20px 50%;
    }
    &.icon02 {
        background: url(#{$base-url}img/common/icon_price.svg) no-repeat 20px 50%;
    }
    &.icon03 {
        background: url(#{$base-url}img/common/icon_calendar.svg) no-repeat 20px 50%;
    }
    &.icon04 {
        background: url(#{$base-url}img/common/icon_disclaimer.svg) no-repeat 20px 50%;
        background-size: 14px auto;
    }
    &:hover {
        &.icon01 {
            background: $color04 url(#{$base-url}img/common/icon_stock_white.svg) no-repeat 20px 50%;
        }
        &.icon02 {
            background: $color04 url(#{$base-url}img/common/icon_price_white.svg) no-repeat 20px 50%;
        }
        &.icon03 {
            background: $color04 url(#{$base-url}img/common/icon_calendar_white.svg) no-repeat 20px 50%;
        }
        &.icon04 {
            background: $color04 url(#{$base-url}img/common/icon_disclaimer_white.svg) no-repeat 20px 50%;
            background-size: 14px auto;

        }
    }
    @include media-down(sp) {
        width: calc(50% - 8px);
        margin-bottom: 10px;
        a {
            font-size: 13px;
            padding: 13px 15px 12px 50px;
        }
    }
    @include media-down(480) {
        width: 100%;
        margin-bottom: 10px;
    }
}
.discList {
    list-style-type: disc;
    padding-left: 1.2em;
}
.decimalList {
    list-style-type: decimal;
    padding-left: 1.2em;
}



// ir calendar
.yearBox {
    margin-bottom: 80px;
    .ttlYear {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 25px;
        margin-left: 10px;
    }
    .newList {
        li {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            padding: 18px 10px 16px;
            line-height: 1.57;
            border-top: 1px solid $color01;
            &:last-child {
                border-bottom: 1px solid $color01;
            }
        }
        .date {
            margin-right: 20px;
            min-width: 107px;
        }
        // .linkPdf,
        .linkPdf a {
            color: $color19;
            background: url(#{$base-url}img/common/icon_pdf.svg) no-repeat 100% 50%;
            padding-right: 28px;
        }
        .newDetail {
            font-size: 16px;
            line-height: 1.6;
            span {
                display: block;
            }
        }
    }
    @include media-down(sp) {
        margin-bottom: 50px;
        .ttlYear {
            font-size: 22px;
            margin-bottom: 20px;
        }
        .newList {
            li {
                font-size: 13px;
                padding: 15px 10px 13px;
            }
            .date {
                margin-right: 15px;
                min-width: 100px;
            }
            .newDetail {
                font-size: 15px;
            }
            .linkPdf {
                background-size: 15px auto;
                padding-right: 22px;
            }
        }
    }
    @include media-down(480) {
        .newList {
            li {
                flex-wrap: wrap;
            }
            .date {
                width: 100%;
                min-width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
}
