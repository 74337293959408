// -----------------------------------------------------------------------------
// This file contains styles that are specific to the IR page.
// -----------------------------------------------------------------------------
#pageIr {
    #contents {
        padding-top: 120px;
        @include media-down(sp) {
            padding-top: 70px;
        }
    }
    .pdfemb-viewer {
        margin-bottom: 30px;
    }
    .irList.mTop {
        margin-top: 150px;
    }
}
