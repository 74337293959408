// -----------------------------------------------------------------------------
// This file contains styles that are specific to the news page.
// -----------------------------------------------------------------------------
.newsArticle {
    margin-bottom: 70px;
    .timeBox {
        margin-bottom: 30px;
        .date {
            display: inline-block;
            margin-right: 13px;
            font-size: 16px;
            line-height: 1.3125;
        }
        .cat {
            display: inline-block;
            font-size: 12px;
            border: 1px solid $text-color;
            line-height: 1.33;
            padding: 2px 5px;
            min-width: 63px;
            text-align: center;
            vertical-align: text-bottom;
        }
    }
    .noteList {
        font-size: 16px;
        line-height: 2;
    }
    .txtN {
        margin-bottom: 30px;
    }
    @include media-down(sp) {
        margin-bottom: 40px;
    }
}
.ttlN {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.75;
    margin-bottom: 25px;
    @include media-down(sp) {
        margin-bottom: 15px;
        font-size: 17px;
    }
}
.articleSection {
    .date {
        font-size: 13px;
        line-height: 1.31;
        margin-bottom: 8px;
    }
    .txtIntro {
        font-size: 13px;
        line-height: 1.5385;
    }
    li {
        border-bottom: 1px solid $color01;
        padding-bottom: 10px;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        color: $text-color;
    }
}
.categorySection {
    .categoryList {
        font-size: 14px;
        line-height: 1.786;
        a {
            color: $text-color;
        }
    }
}
.yearSection {
    .yearList {
        font-size: 14px;
        line-height: 1.786;
        a {
            color: $text-color;
        }
    }
}
#side {
    .section {
        margin-bottom: 50px;
        // &:last-child {
        //     margin-bottom: 0;
        // }
        @include media-down(sp) {
            margin-bottom: 40px;
        }
    }
}


// news detail
.newsArticle {
    p {
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 2;
        &.mBottom {
            margin-bottom: 90px;
        }
        @include media-down(sp) {
            margin-bottom: 20px;
            font-size: 14px;
            &.mBottom {
                margin-bottom: 45px;
            }
        }
    }
}
