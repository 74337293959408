// -----------------------------------------------------------------------------
// All other style
// -----------------------------------------------------------------------------
.jsBgIE {
    &> img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.imgItem.wp-block-image {
    margin-bottom: 35px;
    @include media-down(sp) {
        margin-bottom: 20px;
    }
}
