// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------
body {
    color: $text-color;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.231;
    font-family: $text-font-stack;
    &#pageCompany {
        font-size: 1.6rem;
    }
    &#pageHome {
        font-size: 1.5rem;
    }
}
