// -----------------------------------------------------------------------------
// Pagination style
// -----------------------------------------------------------------------------
.pagerLink {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 40px;
    span, a {
        display: block;
        color: $text-color;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 5px;
        border: 1px solid $color01;
        padding: 8px;
        min-width: 35px;
        text-align: center;
        margin: 0 5px 5px;
        &.current {
            color: $color-white;
            background-color: $color08;
            border-color: $color08;
        }
        &.prevLink {
            padding-left: 35px;
            padding-right: 22px;
            background: image('common/icon_arrow_back.svg') no-repeat 15px 50%;
            background-size: 7px auto;
            margin-left: 0;
        }
        &.nextLink {
            padding-right: 35px;
            padding-left: 22px;
            background: image('common/icon_arrow_next.svg') no-repeat right 15px top 50%;
            background-size: 7px auto;
            margin-right: 0;
        }
    }
    a {
        cursor: pointer;
        transition: opacity 0.3s;
        &:hover {
            opacity: .7;
        }
    }
    @include media-down(1024) {
        span, a {
            padding: 5px;
            min-width: 26px;
            font-size: 12px;
            margin: 0 3px 3px;
            border-radius: 2px;
            &.prevLink {
                padding-left: 25px;
                padding-right: 10px;
                background-position: 10px 50%;
                background-size: 4px auto;
            }
            &.nextLink {
                padding-right: 25px;
                padding-left: 10px;
                background-position: right 10px top 50%;
                background-size: 4px auto;
            }
        }
    }
}
