// -----------------------------------------------------------------------------
// Anchorlink style
// -----------------------------------------------------------------------------
.anchorList {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 20px;
    margin: 0 auto;
    justify-content: center;
    background-color: #F1F1F1;
    margin-bottom: 70px;
    li {
        position: relative;
        padding: 3px 20px;
        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            width: 1px;
            height: 13px;
            background-color: #000;
            transform: translateY(-50%);
            content: "";
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    a {
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #000;
        line-height: 1.3;
        text-decoration: none;
        transition: $transition-duration;
        &:hover {
            opacity: .7;
        }
    }
    @include media-down(sp) {
        margin-bottom: 35px;
        padding: 15px 0;
        justify-content: flex-start;
    }
}
