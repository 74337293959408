// -----------------------------------------------------------------------------
// This file contains styles that are specific to the faq page.
// -----------------------------------------------------------------------------
.faqBlock {
    border-top: 1px solid $color01;
    margin-bottom: 100px;
    &:last-child {
        margin-bottom: 0;
    }
    .faqQuestion {
        position: relative;
        font-size: 16px;
        line-height: 1.3125;
        padding: 28px 100px 28px 0;
        border-bottom: 1px solid $color01;
        // background: image('common/icon_arrow_down02.svg') no-repeat right 47px top 50%;
        background: url('http://shinwa-wise.test.vm/html/assets/img/common/icon_arrow_down02.svg') no-repeat right 47px top 50%;
        cursor: pointer;
        transition: $transition-duration;
        & > * {
            display: flex;
        }
        &.active {
            background-image: url('http://shinwa-wise.test.vm/html/assets/img/common/icon_arrow_up.svg');
            border-bottom-color: transparent;
        }
        &:hover {
            opacity: .7;
        }
    }
    .icon {
        font-weight: bold;
        width: 45px;
        margin-right: 5px;
    }
    .txt {
        width: calc(100% - 50px);
    }
    .faqAnswer {
        display: none;
        font-size: 15px;
        line-height: 2;
        padding-bottom: 50px;
        padding-right: 100px;
        border-bottom: 1px solid $color01;
        .flexBox {
            & > * {
                display: flex;
            }
        }
    }
    .faqItem {
        border-bottom: 1px solid $color01;
    }
    @include media-down(sp) {
        margin-bottom: 50px;
        .faqQuestion {
            padding: 14px 30px 14px 0;
            background-position: right 15px top 50%;;
        }
        .faqAnswer {
            padding-bottom: 15px;
            padding-right: 30px;
        }
    }
}
