// -----------------------------------------------------------------------------
// Header style
// -----------------------------------------------------------------------------
#header {
    background: $color-white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all .3s;

    &.active {
        .navHeader {
            padding-top: 10px;
        }

        .navWrap #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item > a.mega-menu-link,
        #nav>li>a {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .headerInner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 20px;
    }

    #logo {
        display: block;
        max-width: 390px;
        line-height: 0;
    }

    .navHeader {
        width: calc(100% - 430px);
    }

    .topNav {
        transition: all .3s;

        a {
            display: block;
            font-weight: bold;
            line-height: 1.3;
            color: $text-color;

            &:hover {
                text-decoration: none;
            }
        }

        select {
            border: none;
            outline: none;
            -webkit-appearance: none;
            appearance: none;
            background: image('common/icon_arrow_down.svg') no-repeat 100% 50%;
            background-size: 8px auto;
            font-weight: bold;
            padding: 0 20px 0 0;
            font-size: 13px;
            color: #000;
            cursor: pointer;

            &::-ms-expand {
                display: none;
            }
        }
    }

    #nav {

        a {
            display: block;
            font-weight: bold;
            text-decoration: none;
            line-height: 1.3125;
            color: $text-color;
            transition: all .3s;

            &:hover {
                opacity: .7;
            }
        }
    }

    .navToggle {
        display: none;
        border: none;
        outline: none;
        background: transparent;
        width: 22px;
        height: 74px;
        padding: 0;

        span {
            display: block;
            height: 2px;
            background: $color05;
            transition: all .3s;

            &:nth-child(2) {
                margin: 5px 0;
            }
        }

        &.active {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translateX(1px);
                }

                &:nth-child(2) {
                    display: none;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg) translateX(2px);
                }
            }
        }
    }
    .navWrap {
        #mega-menu-wrap-primary {
            background: transparent !important;
            position: initial;
            #mega-menu-primary {
                position: initial;
                text-align: right;
                & > li.mega-menu-item {
                    &:first-child {
                        & > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu > .mega-menu-column {
                            &:nth-child(2) {
                                width: calc(100% - 641px);
                                max-width: none;
                            }
                            &:nth-child(3) {
                                margin-right: 0;
                            }
                        }
                    }
                    &:last-child > a.mega-menu-link {
                        padding-right: 0;
                    }
                    & > ul.mega-sub-menu {
                        padding: 32px 0 43px;
                        .mega-menu-item {
                            width: auto;
                        }
                    }
                    & > a.mega-menu-link {
                        padding: 21px 15px;
                        text-align: center;
                        color: $text-color;
                        font-size: 16px;
                        font-weight: bold;
                        height: auto;
                        line-height: 1.3125;
                        transition: $transition-duration;
                        &:focus,
                        &:hover {
                            background: transparent !important;
                            opacity: .7;
                        }
                        & > span.mega-indicator {
                            display: none;
                        }
                    }
                    &.mega-toggle-on > a.mega-menu-link {
                        background: transparent;
                    }
                    &.mega-menu-megamenu {
                        & > ul.mega-sub-menu {
                            .mega-menu-row {
                                & > ul.mega-sub-menu {
                                    display: flex;
                                    justify-content: flex-start;
                                    .mega-menu-column {
                                        display: inline-block;
                                        width: auto;
                                        ul.mega-sub-menu {
                                            & > .mega-menu-item {
                                                padding: 0;
                                                .textwidget {
                                                    margin-bottom: 12px;
                                                }
                                            }
                                        }
                                        width: 33.33%;
                                        max-width: 190px;
                                        margin-right: 35px;
                                        &:first-child {
                                            max-width: none;
                                            margin-right: 0;
                                        }
                                        .mega-sub-menu {
                                            padding: 17px 0;
                                        }
                                        &:first-child,
                                        &#mega-menu-792-0-2 {
                                            width: auto;
                                            .mega-sub-menu {
                                                padding: 0;
                                            }
                                        }
                                        &#mega-menu-792-0-1 {
                                            width: calc(100% - 641px);
                                            max-width: none;
                                        }
                                        &:first-child {
                                            ul.mega-sub-menu {
                                                height: 100%;
                                                & > .mega-menu-item {
                                                    height: 100%;
                                                    padding: 0;
                                                    & > h4.mega-block-title {
                                                        height: 100%;
                                                    }
                                                }
                                            }
                                        }
                                        a {
                                            // font-family: 'Hiragino Kaku Gothic', sans-serif;
                                            display: inline-block;
                                            font-size: 16px;
                                            color: $text-color;
                                            line-height: 1.5;
                                            font-weight: bold;
                                            white-space: nowrap;
                                            transition: all 0.3s;
                                            &:hover {
                                                transform: translateX(10px);
                                                opacity: 1;
                                                text-decoration: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mega-current-menu-ancestor,
                    &.mega-current-menu-item,
                    &.mega-current-page-ancestor {
                        & > a.mega-menu-link {
                            background: transparent;
                        }
                    }
                }
                & > li.mega-menu-megamenu {
                    &.mega-menu-item {
                        min-width: 90px;

                    }
                    & > ul.mega-sub-menu {
                        & > li.mega-menu-row {
                            width: 100%;
                            max-width: 1440px;
                            margin: 0 auto;
                            padding: 0 20px;
                            min-height: 163px;

                            float: none;
                            & > ul.mega-sub-menu {
                                & > li.mega-menu-columns-3-of-12 {
                                    float: none;
                                }
                            }
                           .mega-menu-column > ul.mega-sub-menu {
                                & > li.mega-menu-item {
                                    float: none;

                                    h4.mega-block-title {
                                        color: #333;
                                        font-size: 25px;
                                        font-weight: bold;
                                        line-height: 1.54;
                                        padding: 14px 30px 14px 40px;
                                        width: 257px;
                                        border-right: 2px solid #E3E3E3;
                                        margin-right: 50px;
                                        display: flex;
                                    }
                                    // &#mega-menu-item-text-4 {
                                    //     padding: 0;
                                    //     h4.mega-block-title {
                                    //         font-size: 16px;
                                    //         margin-top: 8px;
                                    //         font-weight: bold;
                                    //         padding: 0;
                                    //         border-right: none;
                                    //         margin-right: 0;
                                    //     }
                                    // }
                                    &.widget_media_image {
                                        padding: 0;
                                        line-height: 0;
                                        img {
                                            width: 267px;
                                        }
                                        & + .widget_text {
                                            .mega-block-title {
                                                font-size: 16px;
                                                margin-top: 8px;
                                                font-weight: bold;
                                                padding: 0;
                                                border-right: none;
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-up(1000) {
        #logo {
            transition: opacity .3s;
            &:hover {
                opacity: .7;
            }
        }
        .navHeader {
            display: block !important;
            padding-top: 20px;
            transition: all .3s;
        }

        .topNav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;

            a {
                padding-right: 20px;
                margin-right: 20px;
                border-right: 1px solid $color02;
            }

            li {
                border-right: 1px solid $color02;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                    border-right: none;
                }
            }
        }

        #nav {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            float: right;

            &>li {
                min-width: 90px;

                &>a {
                    position: relative;
                    padding: 21px 15px;
                    z-index: 2;
                    transition: all .3s;
                }

                &:hover {
                    .subNav {
                        top: 100%;
                        visibility: visible !important;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }

            a {
                font-size: 16px;
            }

            .subNav {
                position: absolute;
                width: 100%;
                padding: 32px 0 50px;
                font-family: 'Hiragino Kaku Gothic', sans-serif;
                background-color: $color12;
                left: 0;
                top: calc(100% - 10px);
                visibility: hidden !important;
                opacity: 0;
                z-index: -1;
                transition: all .3s ease;

                .headerInner {
                    align-items: initial;
                    min-height: 163px;
                    align-content: center;
                    justify-content: flex-start;
                }

                .ttl {
                    color: $text-color;
                    font-size: 25px;
                    font-weight: bold;
                    line-height: 1.54;
                    padding: 14px 30px 14px 40px;
                    width: 257px;
                    border-right: 2px solid $color01;
                    margin-right: 50px;
                    display: flex;
                }

                .detail {
                    width: calc(100% - 325px);
                    display: flex;
                    justify-content: space-between;
                    max-width: 1012px;
                }

                .navChild {
                    display: flex;
                    width: calc(100% - 250px);
                    max-width: 570px;
                    padding: 17px 0;
                }

                .txt {
                    font-size: 16px;
                    margin-top: 10px;
                    font-weight: bold;
                }

                .img {
                    line-height: 0;
                }

                .nav {
                    width: 33.33%;
                    margin-right: 35px;

                    a {
                        line-height: 1.5;
                        font-weight: bold;
                        white-space: nowrap;
                        transition: all $transition-duration;
                        &:hover {
                            transform: translateX(10px);
                            opacity: 1;
                        }
                    }

                    li {
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    @include media-down(1100) {
        #logo {
            width: 300px;
        }

        .navHeader {
            width: calc(100% - 330px);
        }
    }

    @include media-down(999) {
        .navToggle {
            display: block;
        }

        #logo {
            max-width: 320px;
            width: calc(100% - 50px);
        }

        .navHeader {
            display: none;
            position: absolute;
            width: 100%;
            height: calc(100vh - 74px);
            left: 0;
            top: 74px;
            background-color: $color14;
            padding: 10px 20px 50px;
            font-size: 20px;
            overflow-y: auto;
            // display: flex;
            flex-wrap: wrap-reverse;
            align-content: flex-end;

            a {
                padding: 12px 0;
            }

            #nav {
                margin: 0 25px 20px 30px;
                .subNav {
                    display: none;
                    padding-top: 15px;
                    .ttl {
                        display: none;
                    }
                    .detail {
                        width: 100%;
                    }
                    a {
                        padding: 0;
                        font-size: 15px;
                        margin-bottom: 15px;
                        font-weight: 500;
                    }
                    .imgBox {
                        display: none;
                    }
                    .headerInner {
                        padding: 0;
                    }
                }
                & > li > a {
                    padding: 17px 35px 17px 0;
                    &.iconPlus {
                        position: relative;
                        &:before {
                            position: absolute;
                            height: 21px;
                            width: 1px;
                            content: "";
                            background-color: $color09;
                            top: calc(50% - 10px);
                            right: 15px;
                            transition: all 0.3s;
                        }
                        &:after {
                            position: absolute;
                            width: 21px;
                            height: 1px;
                            content: "";
                            background-color: $color09;
                            right: 5px;
                            top: 50%;
                            transition: all 0.3s;
                        }
                        &.active {
                            &:before {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }

            }
        }
        .topNav {
            border-top: 1px solid $color15;
            padding-top: 25px;
            margin: 0 30px;
            width: 100%;
            .lang {
                position: absolute;
                top: 25px;
                right: 20px;
                font-size: 14px;
                z-index: 2;
                select {
                    background-position: right 15px top 50%;
                    padding: 10px 30px 10px 15px;
                    min-width: 86px;
                    text-align: center;
                    border: 1px solid $color15;
                    border-radius: 5px;
                    text-align-last: center;
                    text-align: center;
                    -ms-text-align-last: center;
                    -moz-text-align-last: center;
                }
            }
            a {
                font-size: 16px;
            }
        }
        .navWrap {
            position: relative;
            display: flex;
            flex-wrap: wrap-reverse;
            padding-top: 75px;
            margin: 0 15px;
            width: 100%;
            .topNav {
                width: 100%;
            }
            #nav {
                width: 100%;
            }
            #mega-menu-wrap-primary #mega-menu-primary > li.mega-menu-item {
                display: block !important;
                & > a.mega-menu-link {
                    text-align: left;
                    display: block;
                    position: relative;
                    &[aria-haspopup="true"]{
                        padding: 17px 35px 17px 15px;
                        &:after {
                            display: inline-block;
                            position: absolute;
                            width: 21px;
                            height: 1px;
                            content: '';
                            background-color: $color09;
                            right: 11px;
                            top: 50%;
                            transition: all 0.3s;
                        }
                        &:before {
                            position: absolute;
                            height: 21px;
                            width: 1px;
                            content: "";
                            background-color: #707070;
                            top: calc(50% - 10px);
                            right: 15px;
                            transition: all 0.3s;
                        }
                        &.active {
                            &:before {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }
        }
        .primary-menu-wrapper {
            width: 100%;
            margin-bottom: 20px;
            #mega-menu-wrap-primary {
                .mega-menu-toggle {
                    display: none;
                    & + #mega-menu-primary {
                        display: block;
                        background: transparent;
                    }
                }
                #mega-menu-primary {
                    & > li.mega-menu-item.mega-menu-megamenu > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu .mega-menu-column {
                        .mega-sub-menu {
                            padding: 0 0 0 0;
                        }
                        &#mega-menu-792-0-2,
                        &:first-child {
                            display: none !important;
                        }
                        margin-right: 0;
                        &#mega-menu-792-0-1 {
                            width: 100%;
                        }
                        &#mega-menu-829-0-2 {
                            display: none;
                        }
                        a {
                            display: block;
                            text-decoration: none;
                            line-height: 1.3125;
                            padding: 0;
                            font-size: 15px;
                            margin-bottom: 15px;
                            font-weight: 500;
                        }
                    }
                    & > li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
                        min-height: 0;
                        padding: 0 15px;
                    }
                    & > li.mega-menu-megamenu > ul.mega-sub-menu {
                        position: relative;
                        display: none;
                        padding: 0;
                        transform: translate(0,0) !important;
                        opacity: 1;
                        background: transparent;
                        visibility: visible !important;
                        & > .mega-menu-row {
                            & > .mega-sub-menu {
                                flex-wrap: wrap;
                                & > .mega-menu-column {
                                    width: 100% !important;
                                    max-width: none !important;
                                    float: none;
                                }
                            }
                        }
                    }
                    & > li.mega-menu-item {
                        & > a.mega-menu-link {
                            font-size: 20px;
                            padding: 17px 35px 17px 15px !important;
                            & + .mega-sub-menu {
                                padding-top: 15px;

                            }
                        }
                    }
                    & > li.mega-menu-item:first-child > ul.mega-sub-menu .mega-menu-row > ul.mega-sub-menu > .mega-menu-column:nth-child(3) {
                        display: none;
                    }
                }
            }
        }
    }
}
