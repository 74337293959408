// -----------------------------------------------------------------------------
// Breadcrumbs style
// -----------------------------------------------------------------------------
.breadcrumb {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        line-height: 1.33;
        &+li {
            &:before {
                padding: 0 4px;
                font-size: 12px;
                content: "\FF1E";
            }
        }
    }

    a {
        color: $text-color;
    }
}
.breadcrumbWrap {
    background-color: $color07;
    padding: 12px 0;
    .breadcrumb {
        justify-content: flex-end;
        li {
            font-size: 12px;
        }
    }

}
