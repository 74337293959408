// -----------------------------------------------------------------------------
// Keyv style
// -----------------------------------------------------------------------------
#keyv {
    &.keyv {
        position: relative;
        height: 370px;

        .inner {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding-top: 60px;
            padding-bottom: 20px;
        }
        .ttl {
            font-family: 'Yu Gothic', "YuGothic", "Yu Gothic Medium", sans-serif;
            width: 100%;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.325;
            color: $color-white;
            margin-bottom: 15px;
        }
        .breadcrumb {
            color: $color-white;
            li {
                margin: 3px 0;
                font-size: 16px;
            }
            a {
                color: $color-white;
            }
        }
        @include media-down(sp) {
            height: 200px;

            .inner {
                padding-top: 20px;
                padding-bottom: 10px;
            }
            .ttl {
                margin-bottom: 10px;
                font-size: 25px;
            }
            .breadcrumb {
                li {
                    font-size: 13px;
                }
            }
        }
    }
    .keyvInner {
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .keyvTopCarousel {
        position: relative;
        margin-bottom: 70px;

        .keyvContent {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            z-index: 1;
            color: $color-white;
            transform: translateY(-50%);
            .keyvInner {
                width: calc(100% - 40px);
            }
        }

        .ttl {
            font-family: 'Trajan Pro';
            margin-bottom: 20px;
            font-size: 46px;
            line-height: 1.48;
        }

        .txtIntro {
            margin-bottom: 45px;
            font-size: 16px;
            font-weight: bold;
            line-height: 2.125;

        }

        .swiper-slide {
            .img {
                position: relative;
                line-height: 0;
                height: 649px;
                overflow: hidden;
            }

            img {
                max-width: none;
                min-height: 100%;
                min-width: 100%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .swiper-pagination {
            position: relative;
            line-height: 0;
            margin-top: 27px;
            width: 100%;
        }

        .swiper-pagination-bullet {
            margin-right: 22px;
            outline: none;

            &:last-child {
                margin-right: 0;
            }

            &-active {
                background-color: $color10;
            }
        }

        .btn {
            font-family: "YuGothic";
            font-size: 16px;
            padding: 13px 60px 13px 26px;
            background-position: right 30px top 50%;
        }
    }

    @include media-down(sp) {
        .keyvTopCarousel {
            margin-bottom: 35px;

            .keyvContent {
                bottom: 45px;
                top: auto;
                transform: translateY(0);
            }

            .ttl {
                font-size: 26px;
                line-height: 1.54;
            }

            .txtIntro {
                margin-bottom: 50px;
                font-size: 14px;
                line-height: 1.714;

            }

            .swiper-slide {
                .img {
                    height: 606px;
                    position: relative;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .keyvInner {
                width: calc(100vw - 39px);
                padding: 0 19px 0 20px;
            }

            .btn {
                font-size: 15px;
                padding: 12px 34px 12px 12px;
                background-position: right 14px top 50%;
            }

            .swiper-pagination {
                display: none;
            }
        }
    }
}
.n2-ss-slider {
    margin-bottom: 70px;
    .btn {
        &.transparent {
            a {
                font-size: 16px !important;
                padding: 14px 60px 14px 26px !important;
                background-color: transparent !important;
                color: $color-white !important;
                background: image('common/icon_arrow_right_white.svg') no-repeat right 30px top 50% !important;
                border: 1px solid #e3e3e3 !important;
                transition: all .3s;
                &:hover {
                    opacity: 1;
                    background-color: $color04 !important;
                    border-color: $color04 !important;
                }
            }
            &.iconBlank a {
                background: image('common/icon_blank_white.svg') no-repeat right 22px top 50% !important;
                background-size: 13px 13px;
                &:hover {
                    opacity: 1;
                    background-color: $color04 !important;
                    border-color: $color04 !important;
                }
            }
        }
    }
    .n2-ss-img-wrapper,
    .ttl {
        margin-bottom: 33px !important;
        font-size: 46px !important;
        .n2-font-38928b24d0c5cc267c0574126be47991-hover {
            font-size: 100% !important;
        }
    }
    .txtIntro {
        margin-bottom: 45px !important;
        font-size: 16px !important;
        &.mb0 {
            margin-bottom: 0 !important;
        }

    }
    .n2-ss-layer[data-sstype=content] {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        z-index: 1;
        color: #FFF;
        transform: translateY(-50%);
    }
    .n2-ss-section-main-content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 20px !important;
    }
    // .n2-ss-section-main-content,
    .n2-ss-slider-2,
    .n2-ss-layers-container,
    .n2-ss-slide {
        height: 649px !important;
    }
    [data-hash=a01b6d666a4bce684466af3f707b1047] {
        background-position: right 25% bottom 0 !important;
    }
    .n2-ss-layer {
        padding-right: 44%;
    }

    @include media-down(sp) {
        margin-bottom: 35px;
        .n2-ss-slider-2,
        .n2-ss-layers-container,
        .n2-ss-slide {
            height: 606px !important;
        }
        .ttl {
            font-size: 26px !important;
            line-height: 1.54 !important;
        }
        .txtIntro {
            margin-bottom: 50px !important;
            font-size: 14px !important;
            line-height: 1.714 !important;
            &.hasImg {
                position: relative;
                padding-right: 40%;
            }
        }
        .btn.transparent a {
            font-size: 15px !important;
            padding: 12px 34px 12px 12px !important;
            background-position: right 14px top 50% !important;
        }
        .n2-ss-control-bullet {
            display: none;
        }
        [data-hash=c59b886d1f0387d34f15154dc269fe6f] {
            background-image: url(/wp-content/uploads/2020/09/img_slide01_sp.jpg) !important;
        }
        [data-hash=a01b6d666a4bce684466af3f707b1047] {
            background-image: url(#{$base-url}img/home/img_slide02_sp.jpg) !important;
            background-position: 0 0 !important;
            background-size: cover;
        }
        .n2-ss-section-main-content {
            justify-content: flex-end !important;
            padding-bottom: 45px !important;
        }
        .n2-ss-layer {
            padding-right: 0;
        }
        .n2-ss-img-wrapper img {
            max-width: 334px !important;
        }
        .prStyle {
            padding-right: 40%;
        }
    }
}
div#n2-ss-2 .n2-ss-layer-content {
    background-size: 25% auto !important;
    background-position: right 20px top 50% !important;
}
@include media-up(1100) {
    div#n2-ss-2 .n2-ss-layer-content {
        background-size: 280px auto !important;
    }
}
@include media-down(sp) {
    div#n2-ss-2 .n2-ss-layer-content {
        background-position: right 20px bottom 45px!important;
        background-size: 30% auto!important;
    }
}
